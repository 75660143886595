/**
 * Intro page
 * @author Gabe Abrams
 */

// Import react
import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

// Import shared types
import ServiceTopComponentProps from '../../shared/types/ServiceTopComponentProps';

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const Intro: React.FC<ServiceTopComponentProps> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* --------------- Props -------------*/

  // Destructure all props
  const {
    goToNextService,
  } = props;

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div className="Welcome-outer-container pt-3">
      {/* Greeting */}
      <h2 className="mb-0 text-center fw-bold">
        Welcome!
      </h2>
      <div className="mb-3 text-center">
        Let&apos;s install some outcome-focused applications.
      </div>

      {/* Buttons */}
      <div className="text-center">
        <button
          type="button"
          className="btn btn-lg btn-primary"
          aria-label="begin setting up your course"
          onClick={goToNextService}
        >
          Begin
          <FontAwesomeIcon
            icon={faChevronRight}
            className="ms-2"
          />
        </button>
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

export default Intro;
