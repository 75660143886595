// Import services
import Intro from './Intro';
import AppStore from './AppStore';
// import ContentMigrator from './ContentMigrator';
// import TrainingsList from './TrainingsList';

// Import shared types
import Service from '../shared/types/Service';

/**
 * List of services in order
 * @author Gabe Abrams
 */
const services: Service[] = [
  {
    name: 'intro',
    title: 'Intro',
    component: Intro,
  },
  {
    name: 'app-store',
    title: 'Apps',
    component: AppStore,
  },
  // {
  //   name: 'content-migrator',
  //   title: 'Content',
  //   component: ContentMigrator,
  // },
  // {
  //   name: 'trainings-list',
  //   title: 'Trainings',
  //   component: TrainingsList,
  // },
];

export default services;
