/**
 * Modal that shows more info for an app
 * @author Gabe Abrams
 * @author Yuen Ler Chow
 */

// Import React
import React, { useReducer } from 'react';

// Import dce-reactkit
import { Modal, ModalSize, CopiableBox } from 'dce-reactkit';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownLeftAndUpRightToCenter, faExternalLinkSquare } from '@fortawesome/free-solid-svg-icons';

// Import markdown
import Markdown from 'markdown-to-jsx';

// Import shared types
import App from '../../../shared/types/from-server/stored/App';
import Screenshot from '../../../shared/types/from-server/stored/Screenshot';

// Import other components
import AppBox from './AppBox';

// Import shared constants
import DEFAULT_SUPPORT_EMAIL from '../../../shared/constants/DEFAULT_SUPPORT_EMAIL';

// Import style
import './AppMoreInfoModal.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // App to show more info for
  app: App,
  // If true, the user is an admin
  isAdmin: boolean,
  // Function to call when done
  onClose: () => void,
  // True if app is installed
  isInstalled: boolean,
  // True if app is requested
  isRequested: boolean,
  // Function to call when user wants to add the app
  onAdd: () => void,
  // Function to call when user wants to remove the app
  onRemove: () => void,
  // Function to call when user wants to show how to find the app
  onShowHowToFind: () => void,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // Currently fullscreened screenshot, if there is one
  fullscreenScreenshot?: Screenshot,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Show a screenshot in fullscreen
  ShowScreenshot = 'ShowScreenshot',
  // Hide the fullscreen screenshot
  HideScreenshot = 'HideScreenshot',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.ShowScreenshot,
    // Screenshot to show fullscreen
    screenshot: Screenshot,
  }
  | {
    // Action type
    type: (
      | ActionType.HideScreenshot
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ShowScreenshot: {
      return {
        ...state,
        fullscreenScreenshot: action.screenshot,
      };
    }
    case ActionType.HideScreenshot: {
      return {
        ...state,
        fullscreenScreenshot: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AppMoreInfoModal: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    app,
    isAdmin,
    onClose,
    isInstalled,
    isRequested,
    onAdd,
    onRemove,
    onShowHowToFind,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {};

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    fullscreenScreenshot,
  } = state;

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // If full-screening a screenshot, show that modal instead
  if (fullscreenScreenshot) {
    return (
      <Modal
        key="screenshot"
        title={`Screenshot: ${fullscreenScreenshot.title}`}
        size={ModalSize.ExtraLarge}
        onClose={() => {
          dispatch({
            type: ActionType.HideScreenshot,
          });
        }}
      >
        <div className="AppMoreInfoModal-fullscreen-screenshot-container">
          <img
            className="AppMoreInfoModal-fullscreen-screenshot img-fluid"
            src={fullscreenScreenshot.url}
            alt={`Screenshot of app titled "${fullscreenScreenshot.title}"`}
          />
          <div className="mt-2">
            <button
              type="button"
              className="btn btn-secondary"
              aria-label="exit fullscreen screenshot mode"
              onClick={() => {
                dispatch({
                  type: ActionType.HideScreenshot,
                });
              }}
            >
              <FontAwesomeIcon
                icon={faDownLeftAndUpRightToCenter}
                className="me-1"
              />
              Close Screenshot
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      key="info"
      title="App Information"
      size={ModalSize.ExtraLarge}
      onClose={onClose}
    >
      <AppBox
        isRequested={isRequested}
        app={app}
        isAdmin={isAdmin}
        isInstalled={isInstalled}
        onAdd={onAdd}
        onRemove={onRemove}
        onShowHowToFind={onShowHowToFind}
      />

      <hr />

      <div>
        {/* Long description of the app */}
        <div className="mb-3">
          <Markdown>
            {app.longDescription}
          </Markdown>
          {/* Show link to general info guide if one exists */}
          {app.generalGuideURL && (
            <div>
              <a
                rel="noopener noreferrer"
                target="_blank"
                aria-label="show the full guide for this app in another tab"
                href={app.generalGuideURL}
              >
                Read more...
              </a>
            </div>
          )}
        </div>

        {/* Screenshots */}
        {app.screenshots && app.screenshots.length > 0 && (
          <div className="AppMoreInfoModal-screenshots-container mb-3">
            <h5 className="fw-bold mb-1">
              Screenshots:
            </h5>
            <div className="d-flex flex-wrap gap-2">
              {app.screenshots.map((screenshot) => {
                return (
                  // eslint-disable-next-line max-len
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                  <div
                    className="AppMoreInfoModal-screenshot-container card overflow-hidden"
                    onClick={(e) => {
                      // Skip if user clicked a button
                      if ((e.target as HTMLElement).tagName === 'BUTTON') {
                        return;
                      }
                      dispatch({
                        type: ActionType.ShowScreenshot,
                        screenshot,
                      });
                    }}
                  >
                    <img
                      key={screenshot.url}
                      className="AppMoreInfoModal-screenshot img-fluid"
                      src={screenshot.url}
                      alt={`Screenshot of app titled "${screenshot.title}"`}
                    />
                    <div className="card-body bg-secondary text-white p-1 text-center">
                      <h5 className="card-title m-0">
                        <button
                          type="button"
                          className="AppMoreInfoModal-open-screenshot-button btn btn-secondary"
                          aria-label={`show screenshot of app titled "${screenshot.title}" in fullscreen`}
                          onClick={() => {
                            dispatch({
                              type: ActionType.ShowScreenshot,
                              screenshot,
                            });
                          }}
                        >
                          {screenshot.title}
                        </button>
                      </h5>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* Display help email and copy button */}
        <h5 className="fw-bold mb-1">
          Need help with this app?
        </h5>
        <div className="d-flex align-items-center justify-content-start flex-wrap">
          {/* Show link to more info guide if one exists */}
          {app.generalGuideURL && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="btn btn-secondary text-nowrap me-2 mt-1"
              aria-label="show the full guide for this app in another tab"
              href={app.generalGuideURL}
            >
              More Info
              {' '}
              <FontAwesomeIcon
                icon={faExternalLinkSquare}
              />
            </a>
          )}
          {/* Show link to usage guide if one exists */}
          {app.usageGuideURL && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="btn btn-secondary text-nowrap me-2 mt-1"
              aria-label="show the full guide for this app in another tab"
              href={app.usageGuideURL}
            >
              Usage Guides
              {' '}
              <FontAwesomeIcon
                icon={faExternalLinkSquare}
              />
            </a>
          )}
          {/* Show link to support email */}
          <div className="d-flex align-items-center justify-content-center flex-grow-1 mt-1">
            <div className="d-inline-block text-nowrap me-2">
              {
                (app.generalGuideURL || app.usageGuideURL)
                  ? 'or email'
                  : 'Email'
              }
            </div>
            <CopiableBox
              text={app.supportEmail ?? DEFAULT_SUPPORT_EMAIL}
              maxTextWidthRem={20.5}
              onClick={() => {
                window.location.href = `mailto:${app.supportEmail ?? DEFAULT_SUPPORT_EMAIL}?subject=I need help with ${app.name}`;
              }}
              textAreaId="AppMoreInfoModal-support-email-address"
              copyButtonId="AppMoreInfoModal-copy-button"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AppMoreInfoModal;
