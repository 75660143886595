// Import shared types
import AddType from '../../../shared/types/from-server/stored/AddType';
import App from '../../../shared/types/from-server/stored/App';
import IconType from '../../../shared/types/from-server/stored/IconType';
import Placement from '../../../shared/types/from-server/stored/Placement';

// Import constants
import INPUT_PLACEHOLDER from './INPUT_PLACEHOLDER';

/**
 * Generate an empty app object
 * @author Gabe Abrams
 */
const genEmptyApp = (): App => {
  return {
    id: INPUT_PLACEHOLDER,
    name: INPUT_PLACEHOLDER,
    category: INPUT_PLACEHOLDER,
    shortDescription: INPUT_PLACEHOLDER,
    longDescription: INPUT_PLACEHOLDER,
    icon: {
      type: IconType.SVG,
      svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 120 120" style="enable-background:new 0 0 120 120;" xml:space="preserve"><path d="M98.5,69c0.7-3,1.1-6,1.1-9c0-2.9-0.4-5.9-1.1-8.9l5.7-5.8c2-2.1,2.5-5.2,1-7.8l-2.9-5c-1.5-2.5-4.4-3.8-7.3-3l-7.8,2  c-4.5-4.2-9.9-7.3-15.7-9l-2.2-7.8C68.5,12,65.9,10,63,10h-5.8l0,0c-2.9,0-5.5,1.9-6.3,4.7l-2.1,7.7c-5.9,1.7-11.4,4.8-15.9,9  l-7.8-2c-2.8-0.7-5.8,0.5-7.2,3l-2.9,5c-1.5,2.5-1.1,5.7,0.9,7.8l5.6,5.8c-0.8,3.1-1.1,6.1-1.1,9.1c0,2.9,0.4,5.9,1.1,8.9l-5.6,5.7  c-2,2-2.5,5.2-1,7.8l2.9,5c1.4,2.5,4.4,3.8,7.2,3.1l7.8-2c4.4,4.2,9.9,7.3,15.7,9.1l2.2,7.8c0.8,2.8,3.4,4.7,6.3,4.7h6l0,0  c2.9,0,5.5-2,6.3-4.8l2.1-7.7c5.9-1.7,11.4-4.8,15.9-9l7.9,2c2.8,0.7,5.8-0.5,7.2-3l2.9-5c1.4-2.5,1-5.7-1-7.8L98.5,69z M60,79.5  c-10.8,0-19.7-8.7-19.7-19.5c0-10.7,8.8-19.5,19.7-19.5c10.8,0,19.6,8.7,19.6,19.5C79.6,70.7,70.8,79.5,60,79.5z"></path></svg>',
      backgroundColor: '#75d5ff',
      paddingPercent: 0,
    },
    placements: [Placement.Navigation],
    addType: AddType.AddLink,
    navMenuName: INPUT_PLACEHOLDER,
    link: INPUT_PLACEHOLDER,
  };
};

export default genEmptyApp;
