/**
 * Modal that shows more info for an app
 * @author Gabe Abrams
 * @author Yuen Ler Chow
 */

// Import React
import React from 'react';

// Import markdown
import Markdown from 'markdown-to-jsx';

// Import dce-reactkit
import {
  Modal,
  ModalSize,
} from 'dce-reactkit';

// Import shared types
import App from '../../../shared/types/from-server/stored/App';
import AddType from '../../../shared/types/from-server/stored/AddType';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // App to show how to find
  app: App,
  // Function to call when done
  onClose: () => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const HowToFindModal: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    app,
    onClose,
  } = props;

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <Modal
      key="how-to-find"
      title="How to Find This App"
      size={ModalSize.ExtraLarge}
      onClose={onClose}
    >
      <div className="mb-2">
        This app is
        {' '}
        <strong>
          already available
        </strong>
        {' '}
        and ready for use.
        Here&apos;s how to find it:
      </div>
      <Markdown>
        {(
          app.addType === AddType.AlreadyAdded
            ? app.howToFindIt
            : 'An error occurred. Please contact support'
        )}
      </Markdown>
    </Modal>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default HowToFindModal;
