// Import react
import ReactDOM from 'react-dom';

// Import caccl
import { sendRequest } from 'caccl/client';

// Import dce reactkit
import { AppWrapper, initClient } from 'dce-reactkit';

// Import bootstrap stylesheet
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';

// Import top-level client
import Client from './Client';

// Import style
import './index.scss';

// Import react helpers
import reportWebVitals from './reportWebVitals';

// Initialize dce-reactkit
initClient({
  sendRequest,
});

// Render the root
const container = document.getElementById('root');
ReactDOM.render(
  <AppWrapper>
    <Client />
  </AppWrapper>,
  container,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
