/**
 * Modal to handle manual install for an install request
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import libs
import Markdown from 'markdown-to-jsx';

// Import dce-reactkit
import {
  Modal,
  ModalType,
  ModalButtonType,
  TabBox,
} from 'dce-reactkit';

// Import shared types
import App from '../../shared/types/from-server/stored/App';
import InstallRequest from '../../shared/types/from-server/stored/InstallRequest';
import AddType from '../../shared/types/from-server/stored/AddType';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // The install request
  request: InstallRequest,
  // The app associated with the install request
  app: App,
  // Handler for when the process is cancelled
  onCancel: () => void,
  // Handler for when the admin confirms that the manual install is done
  onFinished: () => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const ManualInstallModal: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    request,
    app,
    onCancel,
    onFinished,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Modal --------------- */
  /*----------------------------------------*/

  return (
    <Modal
      title="Install Manually"
      type={ModalType.ConfirmCancel}
      confirmLabel="Mark as Completed"
      onClose={(buttonType: ModalButtonType) => {
        if (buttonType === ModalButtonType.Confirm) {
          onFinished();
        } else {
          onCancel();
        }
      }}
    >
      {/* Information */}
      <div className="alert alert-warning">
        <strong>
          {app.name}
          {' '}
          can&apos;t be added automatically
        </strong>
        . See below for info on the manual install process.
      </div>

      {/* Request Info */}
      <TabBox title="Request Info">
        <div>
          <strong>
            Course:
          </strong>
          {' '}
          <a
            href={`https://canvas.harvard.edu/courses/${request.courseId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {request.courseName}
          </a>
        </div>

        <div>
          <strong>
            App:
          </strong>
          {' '}
          {app.name}
        </div>
      </TabBox>

      {/* Start Link */}
      {(app.addType === AddType.Manual && app.startLink) && (
        <TabBox title="Start Link">
          <a
            href={app.startLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* eslint-disable-next-line */}
            {app.startLink.replaceAll('${courseId}', String(request.courseId))}
          </a>
        </TabBox>
      )}

      {/* Instructions */}
      {(app.addType === AddType.Manual && app.instructions) && (
        <TabBox
          title="Instructions"
          noBottomPadding
        >
          <Markdown>
            {app.instructions}
          </Markdown>
        </TabBox>
      )}
    </Modal>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default ManualInstallModal;
