/**
 * Admin panel
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChalkboardUser,
  faNotesMedical,
  faShop,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

// Import react kit
import {
  TabBox,
  confirm,
} from 'dce-reactkit';

// Import shared components
import Header from '../shared/Header';
import ContentContainer from '../shared/ContentContainer';

// Import other components
import AppStoreEditorPanel from './AppStoreEditorPanel';
import InstallRequestManagerPanel from './InstallRequestManagerPanel';
import ApprovedAdminPanel from './ApprovedAdminPanel';
import TrainingCategoriesManagerPanel from './TrainingCategoriesManagerPanel';

// Import style
import './style.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Handler for when the user wants to exit the admin panel
  onExitAdminPanel: () => void,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Admin panel home
  Home = 'home',
  // App store editor
  AppStoreEditor = 'app-store-editor',
  // Install request manager
  InstallRequestManager = 'install-request-manager',
  // Approved admin manager
  ApprovedAdminManager = 'approved-admin-manager',
  // Training categories manager
  TrainingCategoriesManager = 'TrainingCategoriesManager',
}

/* -------- State Definition -------- */

type State = {
  // Current view
  view: (
    | View.Home
    | View.AppStoreEditor
    | View.InstallRequestManager
    | View.ApprovedAdminManager
    | View.TrainingCategoriesManager
  ),
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Show the app store editor
  ShowAppStoreEditor = 'ShowAppStoreEditor',
  // Show the install request manager
  ShowInstallRequestManager = 'ShowInstallRequestManager',
  // Show the approved admin manager
  ShowApprovedAdminManager = 'ShowApprovedAdminManager',
  // Show the training categories manager
  ShowTrainingCategoriesManager = 'ShowTrainingCategoriesManager',
  // Go back to the home page
  GoBackToHome = 'GoBackToHome',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: (
      | ActionType.ShowAppStoreEditor
      | ActionType.ShowInstallRequestManager
      | ActionType.GoBackToHome
      | ActionType.ShowApprovedAdminManager
      | ActionType.ShowTrainingCategoriesManager
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ShowAppStoreEditor: {
      return {
        ...state,
        view: View.AppStoreEditor,
      };
    }
    case ActionType.ShowInstallRequestManager: {
      return {
        ...state,
        view: View.InstallRequestManager,
      };
    }
    case ActionType.ShowApprovedAdminManager: {
      return {
        ...state,
        view: View.ApprovedAdminManager,
      };
    }
    case ActionType.ShowTrainingCategoriesManager: {
      return {
        ...state,
        view: View.TrainingCategoriesManager,
      };
    }
    case ActionType.GoBackToHome: {
      return {
        ...state,
        view: View.Home,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AdminPanel: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    onExitAdminPanel,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    view: View.Home,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;

  /* -------------- Home -------------- */

  if (view === View.Home) {
    // Home page: list of features
    body = (
      <div>
        {/* Dashboards */}
        <TabBox title="Dashboards">
          <div className="AdminPanel-tools-container">
            <button
              type="button"
              id="AdminPanel-show-install-request-dashboard-button"
              className="AdminPanel-tool-button btn btn-secondary btn-lg"
              aria-label="show install request dashboard"
              onClick={() => {
                dispatch({ type: ActionType.ShowInstallRequestManager });
              }}
            >
              <FontAwesomeIcon
                icon={faNotesMedical}
                className="AdminPanel-tool-icon"
              />
              Install Requests
            </button>
          </div>
        </TabBox>

        {/* Configure */}
        <TabBox title="Configure Harvard Hello">
          <div className="AdminPanel-tools-container">
            <button
              type="button"
              id="AdminPanel-configure-app-store-button"
              className="AdminPanel-tool-button btn btn-secondary btn-lg"
              aria-label="edit app store functionality"
              onClick={() => {
                dispatch({ type: ActionType.ShowAppStoreEditor });
              }}
            >
              <FontAwesomeIcon
                icon={faShop}
                className="AdminPanel-tool-icon"
              />
              Edit App Store
            </button>

            <button
              type="button"
              id="AdminPanel-configure-training-categories"
              className="AdminPanel-tool-button btn btn-secondary btn-lg"
              aria-label="edit training categories"
              onClick={() => {
                dispatch({ type: ActionType.ShowTrainingCategoriesManager });
              }}
            >
              <FontAwesomeIcon
                icon={faChalkboardUser}
                className="AdminPanel-tool-icon"
              />
              Training Categories
            </button>

            <button
              type="button"
              id="AdminPanel-configure-app-store-button"
              className="AdminPanel-tool-button btn btn-secondary btn-lg"
              aria-label="manage the list of approved admins"
              onClick={() => {
                dispatch({ type: ActionType.ShowApprovedAdminManager });
              }}
            >
              <FontAwesomeIcon
                icon={faUsers}
                className="AdminPanel-tool-icon"
              />
              Approved Admins
            </button>
          </div>
        </TabBox>
      </div>
    );
  }

  /* -------- App Store Editor -------- */

  if (view === View.AppStoreEditor) {
    // Create body
    body = (
      <AppStoreEditorPanel />
    );
  }

  /* ----- Install Request Manager ---- */

  if (view === View.InstallRequestManager) {
    // Create body
    body = (
      <InstallRequestManagerPanel />
    );
  }

  /* ----- Approved Admin Manager ----- */

  if (view === View.ApprovedAdminManager) {
    // Create body
    body = (
      <ApprovedAdminPanel />
    );
  }

  /* --- Training Categories Manager -- */

  if (view === View.TrainingCategoriesManager) {
    // Create body
    body = (
      <TrainingCategoriesManagerPanel />
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div
      className="progress-bar-striped"
      style={{
        backgroundColor: '#ADE4C0',
        minHeight: '100vh',
      }}
    >
      {/* Header */}
      <Header
        onBackButtonClicked={async () => {
          // Home => Exit Admin Panel
          if (view === View.Home) {
            return onExitAdminPanel();
          }

          // Feature => Home
          if (
            view === View.AppStoreEditor
            || view === View.InstallRequestManager
            || view === View.ApprovedAdminManager
            || view === View.TrainingCategoriesManager
          ) {
            const confirmed = await confirm(
              'Abandon unsaved changes?',
              'If you go back, any unsaved changes will be lost.',
              {
                confirmButtonText: 'Go Back',
                cancelButtonText: 'Resume Editing',
              },
            );
            if (!confirmed) {
              return;
            }
            return dispatch({ type: ActionType.GoBackToHome });
          }
        }}
      />

      {/* Add Body */}
      <ContentContainer>
        {body}
      </ContentContainer>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AdminPanel;
