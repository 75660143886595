/**
 * A single app info box, containing an App name, info, and add button
 * @author Benedikt Arnarsson
 * @author Gabe Abrams
 */

import React, { useReducer } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCheckToSlot,
  faCircleMinus,
  faCirclePlus,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import { alert } from 'dce-reactkit';

// Import shared components
import IconPreview from '../../../../shared/IconPreview';

// Import shared types
import App from '../../../../shared/types/from-server/stored/App';
import AddType from '../../../../shared/types/from-server/stored/AddType';

// Import shared constants
import DEFAULT_SUPPORT_EMAIL from '../../../../shared/constants/DEFAULT_SUPPORT_EMAIL';

// Import style
import './style.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // The App to be displayed
  app: App,
  // Boolean indicating whether the app is installed
  isInstalled: boolean,
  // Boolean indicating whether the app was requested
  isRequested: boolean,
  // If true, the user is an admin
  isAdmin: boolean,
  // Handler for when the user wants to add this app
  onAdd: () => void,
  // Handler for when the user wants to remove this app
  onRemove: () => void,
  // Handler for when the user wants to show more info on this app
  // (if excluded, the component is inline and part of the more info page)
  onShowMoreInfo?: () => void,
  // Handler for when the user wants to show how to find the app
  onShowHowToFind?: () => void,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // True if user is hovering on the box
  hovering: boolean,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Set hovering to true
  HoverStart = 'hover-start',
  // Set hovering to false
  HoverEnd = 'hover-end',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: (
      | ActionType.HoverStart
      | ActionType.HoverEnd
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.HoverStart: {
      return {
        hovering: true,
      };
    }
    case ActionType.HoverEnd: {
      return {
        hovering: false,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AppBox: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    app,
    isAdmin,
    isInstalled,
    isRequested,
    onShowHowToFind,
    onAdd,
    onRemove,
    onShowMoreInfo,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    hovering: false,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    hovering,
  } = state;

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  const isInline = !onShowMoreInfo;

  /* ------ Change Status Button ------ */

  let changeStatusButton: React.ReactNode;

  if (isInstalled) {
    // Installed indicator
    if (app.addType === AddType.Manual && !isAdmin) {
      changeStatusButton = (
        <button
          id={`AppBox-installed-status-button-with-id-${app.id}`}
          type="button"
          className="AppBox-button AppBox-installed-status-button btn btn-dark active"
          aria-label={`indicator that app "${app.name}" is installed`}
          style={{
            boxShadow: 'inset 0.05rem 0.05rem 0.2rem black',
          }}
          onClick={() => {
            alert(
              'Successfully Installed',
              `This app has been successfully installed in your course. If you need to remove it, get in touch with support at ${DEFAULT_SUPPORT_EMAIL}`,
            );
          }}
        >
          <FontAwesomeIcon
            icon={faCheck}
            className="me-1"
            style={{
              pointerEvents: 'none',
            }}
          />
          Installed
        </button>
      );
    } else {
      // Remove button
      changeStatusButton = (
        <button
          id={`AppBox-remove-button-with-id-${app.id}`}
          type="button"
          onClick={onRemove}
          className="AppBox-button AppBox-remove-button btn btn-dark"
          aria-label={`remove app "${app.name}" from course`}
        >
          <FontAwesomeIcon
            icon={faCircleMinus}
            className="me-1"
            style={{
              pointerEvents: 'none',
            }}
          />
          Remove
        </button>
      );
    }
  } else if (isRequested) {
    // Requested (disabled button)
    changeStatusButton = (
      <button
        id={`AppBox-requested-status-button-with-id-${app.id}`}
        type="button"
        disabled
        className="AppBox-button AppBox-requested-status-button btn btn-secondary active"
        aria-label={`indicator that app "${app.name}" has already been requested`}
        style={{
          boxShadow: 'inset 0.05rem 0.05rem 0.2rem black',
        }}
      >
        <FontAwesomeIcon
          icon={faCheckToSlot}
          className="me-1"
          style={{
            pointerEvents: 'none',
          }}
        />
        Requested
      </button>
    );
  } else {
    // Add
    changeStatusButton = (
      <button
        id={`AppBox-add-button-with-id-${app.id}`}
        type="button"
        onClick={(
          app.addType !== AddType.AlreadyAdded
            ? onAdd
            : onShowHowToFind
        )}
        className="AppBox-button AppBox-add-button btn btn-primary"
        aria-label={(
          app.addType !== AddType.AlreadyAdded
            ? `add app "${app.name}" to course`
            : `show how to find app "${app.name}"`
        )}
      >
        <FontAwesomeIcon
          icon={(
            app.addType !== AddType.AlreadyAdded
              ? faCirclePlus
              : faMagnifyingGlass
          )}
          className="me-1"
          style={{
            pointerEvents: 'none',
          }}
        />
        {(
          app.addType !== AddType.AlreadyAdded
            ? 'Add to Course'
            : 'How to Find It'
        )}
      </button>
    );
  }

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  /* eslint-disable jsx-a11y/mouse-events-have-key-events */
  return (
    <div
      className={`AppBox-box d-flex align-items-center justify-content-center ${isInline ? 'mb-2' : 'AppBox-box-hoverable alert alert-secondary mb-2'}`}
      id={`AppBox-with-id-${app.id}`}
      onClick={(
        isInline
          ? undefined
          : (e) => {
            // Skip if user clicked a button
            if ((e.target as HTMLElement).tagName === 'BUTTON') {
              return;
            }
            onShowMoreInfo();
          }
      )}
      onMouseOver={(
        isInline
          ? undefined
          : () => {
            dispatch({ type: ActionType.HoverStart });
          }
      )}
      onMouseOut={(
        isInline
          ? undefined
          : () => {
            dispatch({ type: ActionType.HoverEnd });
          }
      )}
    >
      {/* App Icon */}
      <div className="AppBox-icon-container d-none d-md-inline-block">
        <IconPreview
          icon={app.icon}
          sizeInRems={5}
          faded={!hovering && !isInline}
        />
      </div>

      {/* App information */}
      <div className="AppBox-text flex-grow-1">
        {/* Name */}
        <div className="AppBox-name">
          {app.name}
        </div>
        {/* Description */}
        <div className="AppBox-description">
          <div className="AppBox-desc">
            {app.shortDescription}
          </div>
        </div>
      </div>

      {/* Buttons */}
      <div className="AppBox-buttons">
        <div className="d-flex flex-column">
          {changeStatusButton}
          {onShowMoreInfo && (
            <button
              type="button"
              onClick={onShowMoreInfo}
              className="AppBox-show-info btn btn-outline-secondary mt-1"
              aria-label={`show more info for app "${app.name}"`}
            >
              More Info
            </button>
          )}
        </div>

      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AppBox;
