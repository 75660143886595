/**
 * Breadcrumb-style progress bar
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSquare,
  faSquareCheck,
  faPenToSquare,
} from '@fortawesome/free-regular-svg-icons';

// Import services
import services from '../../services';

// Import shared types
import Service from '../types/Service';

// Import style
import './ProgressCrumbs.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // The name of the current service that we're on
  service: Service,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const ProgressCrumbs: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    service,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Determine the index of the current step
  const serviceIndex = services.indexOf(service);

  // Pre-process services
  const serviceCrumbs = (
    services
      // Create crumbs
      .map((oneService, i) => {
        const isCompleted = (i < serviceIndex);
        const inProgress = (i === serviceIndex);

        // Status
        let statusIcon = faSquare;
        let status = 'todo';
        if (inProgress) {
          statusIcon = faPenToSquare;
          status = 'in-progress';
        } else if (isCompleted) {
          statusIcon = faSquareCheck;
          status = 'completed';
        }

        return (
          <div
            key={oneService.name}
            className={`ProgressCrumbs-badge-container ProgressCrumbs-status-${status} d-inline-block ms-1`}
          >
            <div
              className="ProgressCrumbs-text"
              style={{
                color: (
                  inProgress
                    ? 'white'
                    : 'black'
                ),
              }}
            >
              <span className="ProgressCrumbs-icon-container">
                <FontAwesomeIcon
                  icon={statusIcon}
                />
              </span>
              {oneService.title}
            </div>

            <div className="ProgressCrumbs-badge-back" />
          </div>
        );
      })
  );

  return (
    <div
      className="d-flex flex-row align-items-top justify-content-center"
    >
      <div>
        Progress:
      </div>
      <div>
        {serviceCrumbs}
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default ProgressCrumbs;
