/**
 * Header and intro card
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faUserCog } from '@fortawesome/free-solid-svg-icons';

// Import other components
import HelloIcon from './HelloIcon';
import Typewriter from './Typewriter';
import ProgressCrumbs from './ProgressCrumbs';

// Import shared types
import Service from '../types/Service';

// Import style
import './style.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Course name
  courseName?: string,
  // Current service (if excluded, in the admin panel)
  service?: Service,
  // Handler for when user clicks the "Admin Panel" button
  onShowAdminPanelClicked?: () => void,
  // Handler for when user clicks the "Back" button
  onBackButtonClicked?: () => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const Header: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    courseName,
    service,
    onShowAdminPanelClicked,
    onBackButtonClicked,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  const headerTitle = (
    service
      ? null
      : 'Admin Panel'
  );

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  const headerInnerHeightRem = (
    6
    + (service ? 2 : 0)
  );

  return (
    <div
      className="Header-outer-container"
      style={{
        height: `${headerInnerHeightRem + 0.2}rem`,
      }}
    >
      <div
        className="Header-container"
        style={{
          height: `${headerInnerHeightRem}rem`,
        }}
      >
        {/* Header Content */}
        <div className="Header-content position-relative">
          {/* Admin Button */}
          {onShowAdminPanelClicked && (
            <span className="Header-admin-button-container">
              <button
                type="button"
                id="Header-show-admin-panel-button"
                className="Header-admin-button btn btn-success progress-bar-striped"
                onClick={onShowAdminPanelClicked}
                aria-label="show admin panel"
              >
                <FontAwesomeIcon
                  icon={faUserCog}
                  className="me-1"
                />
                Admin Panel
              </button>
            </span>
          )}

          {/* Back button */}
          {onBackButtonClicked && (
            <span className="Header-back-button-container">
              <button
                type="button"
                id="Header-back-button"
                className="Header-back-button btn btn-primary"
                onClick={onBackButtonClicked}
                aria-label="go back to the previous view"
              >
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className="me-1"
                />
                Back
              </button>
            </span>
          )}

          {/* Header Content */}
          <div className={`d-flex align-items-center justify-content-center w-100 ${service ? '' : 'h-100'}`}>
            {/* Text */}
            <div className="Header-icon-and-text d-flex align-items-center justify-content-center">
              {/* Hello Icon */}
              <div className="Header-hello-icon">
                <HelloIcon
                  key={headerTitle}
                  animate
                />
              </div>
              {/* Title */}
              {headerTitle && (
                <span className="Header-following-text d-none d-md-inline position-relative">
                  <Typewriter
                    key={headerTitle}
                    text={headerTitle}
                    secs={0.7}
                    delaySecs={0.6}
                  />
                </span>
              )}
            </div>
          </div>

          {/* Progress */}
          {service && (
            <div className="Header-progress-container">
              <ProgressCrumbs
                service={service}
              />
            </div>
          )}

          {/* Course Name */}
          {courseName && (
            <div className="Header-course-name text-center">
              Course:
              {' '}
              {courseName}
            </div>
          )}
        </div>

        {/* Header backdrop */}
        <div
          className="Header-backdrop"
          key={(
            service
              ? service.name
              : headerTitle
          )}
        />
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default Header;
