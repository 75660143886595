/**
 * Individual install request item
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import dce-reactkit
import { getHumanReadableDate } from 'dce-reactkit';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRightLong,
  faArrowUpRightFromSquare,
  faCheck,
  faEnvelope,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

// Import shared components
import IconPreview from '../../shared/IconPreview';

// Import shared types
import InstallRequest from '../../shared/types/from-server/stored/InstallRequest';
import App from '../../shared/types/from-server/stored/App';
import InstallRequestStatus from '../../shared/types/from-server/stored/InstallRequestStatus';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Install request to display
  request: InstallRequest,
  // App associated with the install request
  app: App,
  // Handler for when the user clicks the "ignore" button
  onIgnore: () => void,
  // Handler for when the user clicks the "install" button
  onInstall: () => void,
  // Handler for when the user wants to contact the requestor
  onContact: () => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const InstallRequestItem: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    request,
    app,
    onIgnore,
    onInstall,
    onContact,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div
      key={request.id}
      className="alert alert-secondary mb-2 d-flex align-items-center justify-content-center p-2"
    >
      <div className="me-2 d-flex justify-content-center align-items-center">
        <IconPreview
          icon={app.icon}
          sizeInRems={4}
          faded
        />
      </div>
      <div className="flex-grow-1">
        <div>
          <h4 className="m-0 fw-bold">
            {app.name}
            <FontAwesomeIcon
              icon={faArrowRightLong}
              className="text-secondary ms-2 me-2"
            />
            {request.courseName}
          </h4>
        </div>
        <div>
          {request.requestorFirstName}
          {' '}
          {request.requestorLastName}
          {' '}
          <em>
            <small>
              (
              <span className="user-select-all">
                {request.requestorEmail}
              </span>
              )
            </small>
          </em>
          {' '}
          on
          {' '}
          {getHumanReadableDate(request.requestTimestamp)}
        </div>
      </div>

      {/* Stacked Buttons */}
      <div className="d-flex align-items-center justify-content-center">
        <div className="d-grid me-1">
          <a
            href={`https://canvas.harvard.edu/courses/${request.courseId}`}
            target="_blank"
            rel="noreferrer"
            className="btn btn-secondary text-nowrap"
            style={{
              marginBottom: '0.125rem',
            }}
          >
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              className="me-1"
            />
            Canvas
          </a>
          <button
            type="button"
            className="btn btn-secondary text-nowrap"
            aria-label="contact the requestor"
            onClick={onContact}
          >
            <FontAwesomeIcon
              icon={faEnvelope}
              className="me-1"
            />
            Contact
          </button>
        </div>

        {/* Ignore Button */}
        {request.status === InstallRequestStatus.Pending && (
          <div className="me-1">
            <button
              type="button"
              className="btn btn-secondary btn-lg"
              aria-label={`ignore request for ${app.name} for course ${request.courseName}`}
              onClick={onIgnore}
            >
              <div>
                <FontAwesomeIcon
                  icon={faTimes}
                />
              </div>
              <div className="fw-bold">
                Ignore
              </div>
            </button>
          </div>
        )}

        {/* Install Button */}
        {request.status !== InstallRequestStatus.Finished && (
          <div>
            <button
              type="button"
              className="btn btn-primary btn-lg"
              aria-label={`install request for ${app.name} for course ${request.courseName}`}
              onClick={onInstall}
            >
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                />
              </div>
              <div className="fw-bold">
                Install
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default InstallRequestItem;
