/**
 * Panel for adding or editing an app
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileImage,
  faSave,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  LoadingSpinner,
  CheckboxButton,
  ButtonInputGroup,
  RadioButton,
  getTimeInfoInET,
  Drawer,
  showFatalError,
  alert,
  visitServerEndpoint,
  confirm,
} from 'dce-reactkit';

// Import shared types
import App from '../../../shared/types/from-server/stored/App';
import Placement from '../../../shared/types/from-server/stored/Placement';
import AddType from '../../../shared/types/from-server/stored/AddType';
import IconType from '../../../shared/types/from-server/stored/IconType';

// Import shared components
import IconPreview from '../../../shared/IconPreview';

// Import constants
import INPUT_PLACEHOLDER from './INPUT_PLACEHOLDER';

// Import helpers
import genEmptyApp from './genEmptyApp';

// Import style
import './style.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // The app that the user is editing (undefined if adding a new app)
  appToEdit?: App,
  // All the existing apps in the list
  allApps: App[],
  // Handler for when the user finishes adding or editing the app
  // (if no app is returned, process was cancelled)
  onFinished: (app?: App) => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

// Keep track of today's date (based on when the tool was launched)
const today = getTimeInfoInET();

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // The app's current state
  app: App,
  // List of available categories
  categories: string[],
  // True if currently saving
  saving: boolean,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Update the app
  UpdateApp = 'UpdateApp',
  // Add a category to the list
  AddCategory = 'AddCategory',
  // Start the save spinner
  StartSave = 'StartSave',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.UpdateApp,
    // New state of the app
    app: App,
  }
  | {
    // Action type
    type: ActionType.AddCategory,
    // New category to add
    category: string,
  }
  | {
    // Action type
    type: (
      | ActionType.StartSave
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.UpdateApp: {
      return {
        ...state,
        app: action.app,
      };
    }
    case ActionType.AddCategory: {
      return {
        ...state,
        categories: [
          ...state.categories,
          action.category,
        ],
      };
    }
    case ActionType.StartSave: {
      return {
        ...state,
        saving: true,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AddOrEditApp: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    appToEdit,
    allApps,
    onFinished,
  } = props;

  /* -------------- State ------------- */

  // Determine list of existing categories
  const existingCategories: string[] = [];
  allApps.forEach((app) => {
    if (!existingCategories.includes(app.category)) {
      existingCategories.push(app.category);
    }
  });

  // Initial state
  const initialState: State = {
    app: JSON.parse(JSON.stringify(appToEdit ?? genEmptyApp())),
    categories: existingCategories,
    saving: false,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    app,
    categories,
    saving,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Save changes to the app and then finish
   * @author Gabe Abrams
   */
  const save = async () => {
    // Start the save loading indicator
    dispatch({ type: ActionType.StartSave });

    // Clean up the app
    const cleanedApp = app;
    if (cleanedApp.addType === AddType.EnableLTI) {
      cleanedApp.appIds = cleanedApp.appIds.filter((id) => {
        return (id > 0);
      });
    }

    // Send to server
    try {
      await visitServerEndpoint({
        path: '/api/admin/services/app-store/apps',
        method: 'POST',
        params: {
          app: JSON.stringify(cleanedApp),
        },
      });

      // Finish
      onFinished(cleanedApp);
    } catch (err) {
      return showFatalError(err);
    }
  };

  /**
   * Cancel and return without saving
   * @author Gabe Abrams
   */
  const cancel = async () => {
    onFinished(undefined);
  };

  /**
   * Handle when user adds an icon file
   * @author Gabe Abrams
   * @param e file input event
   */
  const onIconFileAdded = (e: any) => {
    // Get html input
    const target = (e.target as HTMLInputElement);
    if (!target) {
      return alert(
        'Oops!',
        'No files were found. Please try again.',
      );
    }

    // Get all files
    const { files } = target;
    if (!files || files.length === 0) {
      return alert(
        'Oops!',
        'No files were selected. Please try again.',
      );
    }

    // Get first file
    const file = files[0];

    // Make sure image is an SVG
    if (!file.type || file.type !== 'image/svg+xml') {
      return alert(
        'Only SVGs are allowed',
        'Please choose an SVG image instead.',
      );
    }

    // Read the file
    try {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onloadend = () => {
        // Get results
        let { result } = (reader as any);
        if (!result) {
          return alert(
            'Oops!',
            'That file could not be read. Please try again.',
          );
        }

        // Overwrite size
        result = result.replace(/width="[^"]*"/, 'width="100%"');
        result = result.replace(/height="[^"]*"/, 'height="100%"');

        // Add results to app
        app.icon = {
          ...app.icon,
          type: IconType.SVG,
          svg: result,
        };

        // Update app
        dispatch({
          type: ActionType.UpdateApp,
          app,
        });
      };
    } catch (err) {
      showFatalError(err);
    }
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body
  let body: React.ReactNode;

  /* ------------- Loading ------------ */

  if (saving) {
    body = (
      <LoadingSpinner />
    );
  }

  /* -------------- Form -------------- */

  if (!saving) {
    // Validation
    const nameInvalid = (app.name.trim().length < 5);
    const categoryInvalid = (
      app.category === INPUT_PLACEHOLDER
      || app.category.trim().length < 3
    );
    const idInvalid = (app.id.trim().length < 5);
    const shortDescriptionInvalid = (app.shortDescription.trim().length < 10);
    const longDescriptionInvalid = (app.longDescription.trim().length < 10);
    // Add app validation
    let addAppInvalid: boolean = false;
    if (app.addType === AddType.AddLink) {
      addAppInvalid = (
        app.navMenuName.trim().length < 5
        || app.link.trim().length < 5
      );
    } else if (app.addType === AddType.EnableLTI) {
      addAppInvalid = app.appIds.length === 0;
    } else if (app.addType === AddType.InstallLTIById) {
      addAppInvalid = (
        app.launchURL.trim().length < 5
        || app.clientId.trim().length < 5
      );
    } else if (app.addType === AddType.InstallLTIByMetadata) {
      addAppInvalid = (
        app.consumerKey.trim().length < 5
        || app.consumerSecret.trim().length < 5
        || app.xml.trim().length < 5
      );
    }
    // Boolean for all of validation
    const validationFailed = (
      nameInvalid
      || categoryInvalid
      || idInvalid
      || shortDescriptionInvalid
      || longDescriptionInvalid
      || addAppInvalid
    );

    // Generate screenshot items
    const screenshotItems: React.ReactNode[] = [];
    // Add existing screenshots
    (app.screenshots ?? []).concat([
      {
        title: INPUT_PLACEHOLDER,
        url: INPUT_PLACEHOLDER,
      },
    ]).forEach((screenshot, i) => {
      const isBlankScreenshot = (i === (app.screenshots ?? []).length);
      const key = i;
      screenshotItems.push(
        <div
          key={key}
          className={`d-flex w-100 align-items-center mb-${isBlankScreenshot ? '0' : '2'}`}
        >
          {/* Title */}
          <div className="flex-grow-1 me-1">
            <input
              type="text"
              className="form-control"
              placeholder="Screenshot Title"
              value={(
                screenshot.title === INPUT_PLACEHOLDER
                  ? ''
                  : screenshot.title
              )}
              onChange={(e) => {
                // Add screenshot if this is the blank screenshot
                if (!app.screenshots || !app.screenshots[i]) {
                  app.screenshots = app.screenshots ?? [];
                  app.screenshots.push({
                    title: '',
                    url: '',
                  });
                }

                // Update screenshot
                app.screenshots[i].title = e.target.value;

                // Remove screenshot if blank
                if (
                  app.screenshots[i].url.trim().length === 0
                  && app.screenshots[i].title.trim().length === 0
                  && !isBlankScreenshot
                ) {
                  app.screenshots.splice(i, 1);
                }

                // Update app
                dispatch({
                  type: ActionType.UpdateApp,
                  app,
                });
              }}
            />
          </div>
          {/* URL */}
          <div className="flex-grow-1 me-1">
            <input
              type="text"
              className="form-control"
              placeholder="Screenshot Image URL"
              value={(
                screenshot.url === INPUT_PLACEHOLDER
                  ? ''
                  : screenshot.url
              )}
              onChange={(e) => {
                // Add screenshot if this is the blank screenshot
                if (!app.screenshots || !app.screenshots[i]) {
                  app.screenshots = app.screenshots ?? [];
                  app.screenshots.push({
                    title: '',
                    url: '',
                  });
                }

                // Update screenshot
                app.screenshots[i].url = e.target.value;

                // Remove screenshot if blank
                if (
                  app.screenshots[i].url.trim().length === 0
                  && app.screenshots[i].title.trim().length === 0
                  && !isBlankScreenshot
                ) {
                  app.screenshots.splice(i, 1);
                }

                // Update app
                dispatch({
                  type: ActionType.UpdateApp,
                  app,
                });
              }}
            />
          </div>
          {/* Remove button */}
          <button
            type="button"
            className="btn btn-secondary"
            onClick={async () => {
              // Skip if this is the blank screenshot
              if (isBlankScreenshot) {
                alert(
                  'Already Empty',
                  'This screenshot is already empty, so you don\'t need to remove it.',
                );
                return;
              }

              // Confirm
              const confirmed = await confirm(
                'Remove Screenshot?',
                'Are you sure you want to remove this screenshot? This cannot be undone.',
              );
              if (!confirmed) {
                return;
              }

              // Remove screenshot
              app.screenshots?.splice(i, 1);

              // Update app
              dispatch({
                type: ActionType.UpdateApp,
                app,
              });
            }}
          >
            <FontAwesomeIcon
              icon={faTrashCan}
            />
          </button>
        </div>,
      );
    });

    // UI
    body = (
      <div>
        <h3>
          {appToEdit ? 'Edit App:' : 'Add App:'}
        </h3>

        {/* Name */}
        <div className="mb-2">
          <div className="input-group">
            <span
              className="AddOrEditApp-input-label input-group-text"
              id="AddOrEditApp-form-name-label"
            >
              Name
            </span>
            <input
              id="AddOrEditApp-form-name-input"
              type="text"
              className="form-control"
              placeholder="Add a human-readable name for the app"
              aria-describedby="AddOrEditApp-form-name-label"
              value={app.name === INPUT_PLACEHOLDER ? '' : app.name}
              onChange={(e) => {
                app.name = (
                  e.target.value
                    .replace(/[^a-zA-Z0-9\s(),-]/g, '')
                    .substring(0, 50)
                );
                dispatch({
                  type: ActionType.UpdateApp,
                  app,
                });
              }}
            />
          </div>
          {nameInvalid && (
            <div className="small text-danger">
              Name is required and must be at least 5 chars.
            </div>
          )}
        </div>

        {/* ID */}
        <div className="mb-2">
          <div className="input-group">
            <span
              className="AddOrEditApp-input-label input-group-text"
              id="AddOrEditApp-form-id-label"
            >
              Hello ID
            </span>
            <input
              id="AddOrEditApp-form-id-input"
              type="text"
              className="form-control"
              placeholder="Required machine-readable id"
              aria-describedby="AddOrEditApp-form-id-label"
              value={app.id === INPUT_PLACEHOLDER ? '' : app.id}
              disabled={!!appToEdit}
              onChange={(e) => {
                app.id = (
                  e.target.value
                    .trim()
                    .toLowerCase()
                    .replace(/[^a-z-]/g, '')
                    .substring(0, 20)
                );
                dispatch({
                  type: ActionType.UpdateApp,
                  app,
                });
              }}
            />
          </div>
          {idInvalid && (
            <div className="small text-danger">
              Hello ID is required and must be at least 5 chars.
            </div>
          )}
        </div>

        {/* Category */}
        <div className="mb-2">
          <ButtonInputGroup
            label="Category"
            minLabelWidth="7.5rem"
          >
            <div className="dropdown">
              {/* Selection */}
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-label="choose a category for the app, show options"
              >
                {
                  app.category === INPUT_PLACEHOLDER
                    ? '–– Choose a category ––'
                    : app.category
                }
              </button>
              {/* Items */}
              <ul className="dropdown-menu">
                {/* Categories */}
                {
                  categories.map((category) => {
                    return (
                      <button
                        key={category}
                        type="button"
                        className="dropdown-item"
                        onClick={() => {
                          app.category = category;
                          dispatch({
                            type: ActionType.UpdateApp,
                            app,
                          });
                        }}
                      >
                        {category}
                      </button>
                    );
                  })
                }
                {/* Add Category */}
                <button
                  key="add-cat-btn"
                  type="button"
                  className="dropdown-item"
                  onClick={() => {
                    // Ask the user for a category
                    // eslint-disable-next-line no-alert
                    const newCategory = prompt('New category name:');

                    // Make sure it's valid
                    if (newCategory === null) {
                      return;
                    }
                    const cleanedNewCategory = newCategory.trim();
                    if (cleanedNewCategory.length < 3) {
                      return alert(
                        'Category Name Too Short',
                        'Category names must be at least 3 characters long.',
                      );
                    }

                    // Add the category
                    dispatch({
                      type: ActionType.AddCategory,
                      category: cleanedNewCategory,
                    });

                    // Update app
                    app.category = cleanedNewCategory;
                    dispatch({
                      type: ActionType.UpdateApp,
                      app,
                    });
                  }}
                >
                  + Add Category
                </button>
              </ul>
            </div>
          </ButtonInputGroup>
          {categoryInvalid && (
            <div className="small text-danger">
              You must choose a category.
            </div>
          )}
        </div>

        {/* Short description */}
        <div className="mb-2">
          <div className="input-group">
            <span
              className="AddOrEditApp-input-label input-group-text"
              id="AddOrEditApp-form-short-description-label"
            >
              Tagline
            </span>
            <input
              type="text"
              id="AddOrEditApp-form-short-description-input"
              className="form-control"
              placeholder="Required short description of the app"
              aria-describedby="AddOrEditApp-form-short-description-label"
              value={app.shortDescription === INPUT_PLACEHOLDER ? '' : app.shortDescription}
              onChange={(e) => {
                app.shortDescription = e.target.value;
                dispatch({
                  type: ActionType.UpdateApp,
                  app,
                });
              }}
            />
          </div>
          {shortDescriptionInvalid && (
            <div className="small text-danger">
              Tagline is required and must be at least 10 chars.
            </div>
          )}
        </div>

        {/* Long description */}
        <div className="mb-2">
          <div className="input-group">
            <span
              className="AddOrEditApp-input-label input-group-text"
              id="AddOrEditApp-form-long-description-label"
            >
              Description
            </span>
            <textarea
              id="AddOrEditApp-form-long-description-input"
              className="form-control"
              placeholder="Required full-length description of the app (markdown supported)"
              aria-describedby="AddOrEditApp-form-short-description-label"
              value={app.longDescription === INPUT_PLACEHOLDER ? '' : app.longDescription}
              rows={6}
              onChange={(e) => {
                app.longDescription = e.target.value;
                dispatch({
                  type: ActionType.UpdateApp,
                  app,
                });
              }}
            />
          </div>
          {longDescriptionInvalid && (
            <div className="small text-danger">
              Description is required and must be at least 10 chars.
            </div>
          )}
        </div>

        {/* Support email */}
        <div className="input-group mb-2">
          <span
            className="AddOrEditApp-input-label input-group-text"
            id="AddOrEditApp-form-support-email-label"
          >
            Support
          </span>
          <input
            id="AddOrEditApp-form-support-email-input"
            type="text"
            className="form-control"
            placeholder="Optional support email (defaults to instructionaltechnology@dce.harvard.edu)"
            aria-describedby="AddOrEditApp-form-support-email-label"
            value={app.supportEmail === INPUT_PLACEHOLDER ? '' : (app.supportEmail ?? '')}
            onChange={(e) => {
              app.supportEmail = (
                e.target.value.trim().length > 0
                  ? e.target.value.trim()
                  : undefined
              );
              dispatch({
                type: ActionType.UpdateApp,
                app,
              });
            }}
          />
        </div>

        {/* Icon */}
        <div className="mb-2">
          <ButtonInputGroup
            label="Icon"
            minLabelWidth="7.5rem"
          >
            {/* Icon Preview */}
            <IconPreview
              icon={app.icon}
              sizeInRems={4}
            />

            {/* Label */}
            <span className="ms-2 me-2">
              Change Icon:
            </span>

            {/* SVG Icon */}
            <label
              className="btn btn-sm btn-secondary me-2"
              htmlFor="AddOrEditApp-svg-icon-upload"
            >
              <span>
                <FontAwesomeIcon
                  icon={faFileImage}
                  className="me-1"
                />
                Upload SVG
                <input
                  type="file"
                  aria-label="icon svg upload"
                  className="d-none"
                  id="AddOrEditApp-svg-icon-upload"
                  accept=".svg"
                  onChange={onIconFileAdded}
                />
              </span>
            </label>

            {/* Background Color */}
            <label
              className="btn btn-sm btn-secondary"
              htmlFor="AddOrEditApp-icon-background-color"
            >
              <span>
                <input
                  type="color"
                  className="form-control d-inline-block me-1"
                  value={app.icon.backgroundColor}
                  aria-label="choose background color for transparent parts of icon"
                  id="AddOrEditApp-icon-background-color"
                  style={{
                    padding: 0,
                    width: '1rem',
                    height: '1rem',
                    transform: 'translate(0, 0.085rem)',
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    // Change app
                    const target = (e as any).target as HTMLInputElement;
                    app.icon.backgroundColor = target.value.trim();

                    // Update app
                    dispatch({
                      type: ActionType.UpdateApp,
                      app,
                    });
                  }}
                />
                Change Background
              </span>
            </label>

            {/* Padding percent */}
            {app.icon.type === IconType.SVG && (
              <select
                id="AddOrEditApp-choose-icon-padding-dropdown"
                className="form-select form-select-sm ms-2"
                aria-label="how much padding to surround the svg image with"
                style={{
                  maxWidth: '10rem',
                }}
                value={app.icon.paddingPercent}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  // Update padding percent
                  const target = (e as any).target as HTMLSelectElement;
                  app.icon.paddingPercent = Number.parseInt(
                    target.selectedOptions[0].value,
                    10,
                  );

                  // Update app
                  dispatch({
                    type: ActionType.UpdateApp,
                    app,
                  });
                }}
              >
                {
                  [
                    {
                      name: 'No Padding',
                      value: 0,
                    },
                    {
                      name: 'Tiny Padding',
                      value: 5,
                    },
                    {
                      name: 'Small Padding',
                      value: 10,
                    },
                    {
                      name: 'Medium Padding',
                      value: 15,
                    },
                    {
                      name: 'Large Padding',
                      value: 20,
                    },
                  ].map((option) => {
                    return (
                      <option
                        key={option.value}
                        value={option.value}
                        id={`AddOrEditApp-choose-icon-padding-${option.value}`}
                      >
                        {option.name}
                      </option>
                    );
                  })
                }
              </select>
            )}
          </ButtonInputGroup>
        </div>

        {/* Placements */}
        <div className="mb-2">
          <ButtonInputGroup
            label="Placements"
            minLabelWidth="7.5rem"
          >
            <CheckboxButton
              id="AddOrEditApp-choose-placement-navigation"
              text="Navigation Menu"
              onChanged={(checked) => {
                if (checked) {
                  app.placements.push(Placement.Navigation);
                } else {
                  app.placements = app.placements.filter((placement) => {
                    return (placement !== Placement.Navigation);
                  });
                }
                dispatch({
                  type: ActionType.UpdateApp,
                  app,
                });
              }}
              ariaLabel="app placement is navigation"
              small
              checked={app.placements.includes(Placement.Navigation)}
            />
            <CheckboxButton
              id="AddOrEditApp-choose-placement-external-assignment"
              text="External Assignment"
              onChanged={(checked) => {
                if (checked) {
                  app.placements.push(Placement.ExternalAssignment);
                } else {
                  app.placements = app.placements.filter((placement) => {
                    return (placement !== Placement.ExternalAssignment);
                  });
                }
                dispatch({
                  type: ActionType.UpdateApp,
                  app,
                });
              }}
              ariaLabel="app placement includes external assignment"
              small
              checked={app.placements.includes(Placement.ExternalAssignment)}
            />
            <CheckboxButton
              id="AddOrEditApp-choose-placement-rich-content-editor"
              text="Rich Content Editor"
              onChanged={(checked) => {
                if (checked) {
                  app.placements.push(Placement.RichContentEditor);
                } else {
                  app.placements = app.placements.filter((placement) => {
                    return (placement !== Placement.RichContentEditor);
                  });
                }
                dispatch({
                  type: ActionType.UpdateApp,
                  app,
                });
              }}
              ariaLabel="app placement includes rich content editor"
              small
              checked={app.placements.includes(Placement.RichContentEditor)}
            />
          </ButtonInputGroup>
        </div>

        {/* General guide URL */}
        <div className="input-group mb-2">
          <span
            className="AddOrEditApp-input-label input-group-text"
            id="AddOrEditApp-form-general-guide-url-label"
          >
            Info Guide
          </span>
          <input
            id="AddOrEditApp-form-general-guide-url-input"
            type="text"
            className="form-control"
            placeholder="Optional URL for guide that provides more info"
            aria-describedby="AddOrEditApp-form-general-guide-url-label"
            value={app.generalGuideURL === INPUT_PLACEHOLDER ? '' : (app.generalGuideURL ?? '')}
            onChange={(e) => {
              app.generalGuideURL = (
                e.target.value.trim().length > 0
                  ? e.target.value.trim()
                  : undefined
              );
              dispatch({
                type: ActionType.UpdateApp,
                app,
              });
            }}
          />
        </div>

        {/* Usage guide URL */}
        <div className="input-group mb-2">
          <span
            className="AddOrEditApp-input-label input-group-text"
            id="AddOrEditApp-form-usage-guide-url-label"
          >
            Usage Guide
          </span>
          <input
            id="AddOrEditApp-form-usage-guide-url-input"
            type="text"
            className="form-control"
            placeholder="Optional URL for usage guide"
            aria-describedby="AddOrEditApp-form-usage-guide-url-label"
            value={app.usageGuideURL === INPUT_PLACEHOLDER ? '' : (app.usageGuideURL ?? '')}
            onChange={(e) => {
              app.usageGuideURL = (
                e.target.value.trim().length > 0
                  ? e.target.value.trim()
                  : undefined
              );

              // For now, we sync the post-add guide URL with the usage guide
              // URL (can be separated later)
              app.postAddGuideURL = app.usageGuideURL;
              dispatch({
                type: ActionType.UpdateApp,
                app,
              });
            }}
          />
        </div>

        {/* Screenshots */}
        <div className="input-group mb-2">
          <ButtonInputGroup
            label="Screenshots"
            minLabelWidth="7.5rem"
          >
            {screenshotItems}
          </ButtonInputGroup>
        </div>

        {/* Permission */}
        <div className="mb-2">
          <ButtonInputGroup
            label="Permission"
            minLabelWidth="7.5rem"
          >
            <RadioButton
              id="AddOrEditApp-permission-self-service-install"
              text="Self-service Install"
              onSelected={() => {
                app.requestOnly = false;
                dispatch({
                  type: ActionType.UpdateApp,
                  app,
                });
              }}
              ariaLabel="anyone can install the app"
              small
              selected={!app.requestOnly}
            />
            <RadioButton
              id="AddOrEditApp-permission-requires-request"
              text="Install Requires Request"
              onSelected={() => {
                app.requestOnly = true;
                dispatch({
                  type: ActionType.UpdateApp,
                  app,
                });
              }}
              ariaLabel="teaching team members must request to install this app"
              small
              selected={app.requestOnly}
            />
          </ButtonInputGroup>
        </div>

        {/* Notice before add */}
        <div className="input-group mb-2">
          <span
            className="AddOrEditApp-input-label input-group-text"
            id="AddOrEditApp-form-message-before-add-label"
            style={{ width: '7em' }}
          >
            <div>
              <div>
                Notice
              </div>
              <div>
                Before
                {' '}
                {app.requestOnly ? 'Req.' : 'Add'}
              </div>
              <div>
                (markdown)
              </div>
            </div>
          </span>
          <textarea
            id="AddOrEditApp-form-message-before-add-input"
            className="form-control"
            placeholder={`Optional notice that the user must agree to before ${app.requestOnly ? 'requesting' : 'adding'} an app (markdown is supported)`}
            aria-describedby="AddOrEditApp-form-message-before-add-label"
            value={app.messageBeforeAdd === INPUT_PLACEHOLDER ? '' : app.messageBeforeAdd}
            rows={6}
            onChange={(e) => {
              app.messageBeforeAdd = e.target.value;
              dispatch({
                type: ActionType.UpdateApp,
                app,
              });
            }}
          />
        </div>

        {/* Notice after add */}
        <div className="input-group mb-2">
          <span
            className="AddOrEditApp-input-label input-group-text"
            id="AddOrEditApp-form-message-after-add-label"
            style={{ width: '7em' }}
          >
            <div>
              <div>
                Notice
              </div>
              <div>
                After
                {' '}
                {app.requestOnly ? 'Req.' : 'Add'}
              </div>
              <div>
                (markdown)
              </div>
            </div>
          </span>
          <textarea
            id="AddOrEditApp-form-message-after-add-input"
            className="form-control"
            placeholder={`Optional notice that the user must agree to after ${app.requestOnly ? 'requesting' : 'adding'} an app (markdown is supported)`}
            aria-describedby="AddOrEditApp-form-message-after-add-label"
            value={app.messageAfterAdd === INPUT_PLACEHOLDER ? '' : app.messageAfterAdd}
            rows={6}
            onChange={(e) => {
              app.messageAfterAdd = e.target.value;
              dispatch({
                type: ActionType.UpdateApp,
                app,
              });
            }}
          />
        </div>

        {/* Start date */}
        <div className="mb-2">
          <ButtonInputGroup
            label="Start"
            minLabelWidth="7.5rem"
          >
            <RadioButton
              id="AddOrEditApp-contract-no-start"
              text="No contract start"
              ariaLabel="indicate that there is no contract start date"
              selected={app.firstUsableMonth === undefined}
              onSelected={() => {
                // Remove last usable date
                app.firstUsableMonth = undefined;
                app.firstUsableDay = undefined;
                app.firstUsableYear = undefined;

                // Update app
                dispatch({
                  type: ActionType.UpdateApp,
                  app,
                });
              }}
              small
            />
            <RadioButton
              id="AddOrEditApp-contract-start-exists"
              text="Contract starts on..."
              ariaLabel="indicate that this app has a contract start date"
              selected={app.firstUsableMonth !== undefined}
              onSelected={() => {
                // Set last usable day to 1/1 of current year
                app.firstUsableMonth = 1;
                app.firstUsableDay = 1;
                app.firstUsableYear = today.year;

                // Update app
                dispatch({
                  type: ActionType.UpdateApp,
                  app,
                });
              }}
              small
            />
            {' '}
            {(
              app.firstUsableMonth !== undefined
              && app.firstUsableDay !== undefined
              && app.firstUsableYear !== undefined
            ) && (
              <div className="input-group input-group-sm">
                <span className="input-group-text">
                  January 1st in
                </span>
                <input
                  type="text"
                  id="AddOrEditApp-contract-start-year"
                  className="form-control"
                  aria-label="year that the contract starts in"
                  value={app.firstUsableYear}
                  onChange={(e) => {
                    // Update dates
                    app.firstUsableYear = Number.parseInt(e.target.value, 10);
                    if (Number.isNaN(app.firstUsableYear)) {
                      app.firstUsableYear = today.year;
                    }

                    // Update app
                    dispatch({
                      type: ActionType.UpdateApp,
                      app,
                    });
                  }}
                />
              </div>
            )}
          </ButtonInputGroup>
        </div>

        {/* End date */}
        <div className="mb-2">
          <ButtonInputGroup
            label="End"
            minLabelWidth="7.5rem"
          >
            <RadioButton
              text="No contract end"
              id="AddOrEditApp-contract-no-end"
              ariaLabel="indicate that there is no contract end date"
              selected={app.lastUsableMonth === undefined}
              onSelected={() => {
                // Remove first usable date
                app.lastUsableMonth = undefined;
                app.lastUsableDay = undefined;
                app.lastUsableYear = undefined;

                // Update app
                dispatch({
                  type: ActionType.UpdateApp,
                  app,
                });
              }}
              small
            />
            <RadioButton
              text="Contract ends on..."
              id="AddOrEditApp-contract-end-exists"
              ariaLabel="indicate that this app has a contract end date"
              selected={app.lastUsableMonth !== undefined}
              onSelected={() => {
                // Set first usable day to last day of current year
                app.lastUsableMonth = 12;
                app.lastUsableDay = 31;
                app.lastUsableYear = today.year;

                // Update app
                dispatch({
                  type: ActionType.UpdateApp,
                  app,
                });
              }}
              small
            />
            {' '}
            {(
              app.lastUsableMonth !== undefined
              && app.lastUsableDay !== undefined
              && app.lastUsableYear !== undefined
            ) && (
              <div className="input-group input-group-sm">
                <span className="input-group-text">
                  December 31st in
                </span>
                <input
                  type="text"
                  id="AddOrEditApp-contract-end-year"
                  className="form-control"
                  aria-label="year that the contract ends in"
                  value={app.lastUsableYear}
                  onChange={(e) => {
                    // Update dates
                    app.lastUsableYear = Number.parseInt(e.target.value, 10);
                    if (Number.isNaN(app.lastUsableYear)) {
                      app.lastUsableYear = today.year;
                    }

                    // Update app
                    dispatch({
                      type: ActionType.UpdateApp,
                      app,
                    });
                  }}
                />
              </div>
            )}
          </ButtonInputGroup>
        </div>

        {/* Add Type */}
        <div>
          <ButtonInputGroup
            label="Add..."
            minLabelWidth="7.5rem"
          >
            <RadioButton
              text="by adding redirect"
              id="AddOrEditApp-add-type-redirect"
              ariaLabel="add app as a nav menu link"
              selected={app.addType === AddType.AddLink}
              onSelected={() => {
                // Update add type
                const updatedApp: App = {
                  ...app,
                  addType: AddType.AddLink,
                  link: INPUT_PLACEHOLDER,
                  navMenuName: INPUT_PLACEHOLDER,
                };

                // Update app
                dispatch({
                  type: ActionType.UpdateApp,
                  app: updatedApp,
                });
              }}
              small
            />
            <RadioButton
              text="by enabling LTI"
              id="AddOrEditApp-add-type-enable-lti"
              ariaLabel="add app by enabling existing LTI"
              selected={app.addType === AddType.EnableLTI}
              onSelected={() => {
                // Update add type
                const updatedApp: App = {
                  ...app,
                  addType: AddType.EnableLTI,
                  appIds: [],
                };

                // Update app
                dispatch({
                  type: ActionType.UpdateApp,
                  app: updatedApp,
                });
              }}
              small
            />
            <RadioButton
              text="by ID"
              id="AddOrEditApp-add-type-by-client-id"
              ariaLabel="add app by its LTI 1.3 id"
              selected={app.addType === AddType.InstallLTIById}
              onSelected={() => {
                // Update add type
                const updatedApp: App = {
                  ...app,
                  addType: AddType.InstallLTIById,
                  launchURL: INPUT_PLACEHOLDER,
                  clientId: INPUT_PLACEHOLDER,
                };

                // Update app
                dispatch({
                  type: ActionType.UpdateApp,
                  app: updatedApp,
                });
              }}
              small
            />
            <RadioButton
              text="by XML"
              id="AddOrEditApp-add-type-xml"
              ariaLabel="add app by its xml"
              selected={app.addType === AddType.InstallLTIByMetadata}
              onSelected={() => {
                // Update add type
                const updatedApp: App = {
                  ...app,
                  addType: AddType.InstallLTIByMetadata,
                  consumerKey: INPUT_PLACEHOLDER,
                  consumerSecret: INPUT_PLACEHOLDER,
                  xml: INPUT_PLACEHOLDER,
                };

                // Update app
                dispatch({
                  type: ActionType.UpdateApp,
                  app: updatedApp,
                });
              }}
              small
            />
            <RadioButton
              text="manually"
              id="AddOrEditApp-add-type-manually"
              ariaLabel="add app manually"
              selected={app.addType === AddType.Manual}
              onSelected={() => {
                // Update add type
                const updatedApp: App = {
                  ...app,
                  addType: AddType.Manual,
                  instructions: undefined,
                  startLink: undefined,
                };

                // Update app
                dispatch({
                  type: ActionType.UpdateApp,
                  app: updatedApp,
                });
              }}
              small
            />
            <RadioButton
              text="already added"
              id="AddOrEditApp-add-type-already-added"
              ariaLabel="already added"
              selected={app.addType === AddType.AlreadyAdded}
              onSelected={() => {
                // Update add type
                const updatedApp: App = {
                  ...app,
                  addType: AddType.AlreadyAdded,
                  howToFindIt: '',
                };

                // Update app
                dispatch({
                  type: ActionType.UpdateApp,
                  app: updatedApp,
                });
              }}
              small
            />
          </ButtonInputGroup>
        </div>

        {/* Add Info */}
        <Drawer>
          {/* Link */}
          {(app.addType === AddType.AddLink) && (
            <div>
              {/* Nave Name */}
              <div className="input-group mb-1">
                <span
                  className="AddOrEditApp-input-label input-group-text"
                  id="AddOrEditApp-form-nav-menu-name-label"
                >
                  Nav Name
                </span>
                <input
                  id="AddOrEditApp-form-nav-menu-name-input"
                  type="text"
                  className="form-control"
                  placeholder="Required name that shows up in navigation menu"
                  aria-describedby="AddOrEditApp-form-nav-menu-name-label"
                  value={app.navMenuName === INPUT_PLACEHOLDER ? '' : (app.navMenuName ?? '')}
                  onChange={(e) => {
                    // Save info to app object
                    app.navMenuName = e.target.value;

                    // Update app
                    dispatch({
                      type: ActionType.UpdateApp,
                      app,
                    });
                  }}
                />
              </div>

              {/* Link */}
              <div className="input-group mb-1">
                <span
                  className="AddOrEditApp-input-label input-group-text"
                  id="AddOrEditApp-form-nav-link-label"
                >
                  Link
                </span>
                <input
                  id="AddOrEditApp-form-nav-link-input"
                  type="text"
                  className="form-control"
                  placeholder="Required link to add to the nav menu"
                  aria-describedby="AddOrEditApp-form-nav-link-label"
                  value={app.link === INPUT_PLACEHOLDER ? '' : (app.link ?? '')}
                  onChange={(e) => {
                    // Save info to app object
                    app.link = e.target.value.trim();

                    // Update app
                    dispatch({
                      type: ActionType.UpdateApp,
                      app,
                    });
                  }}
                />
              </div>

              {/* Hidden from Students */}
              <ButtonInputGroup
                label="Visibility"
                minLabelWidth="7.5rem"
              >
                <RadioButton
                  text="Visible to Students"
                  id="AddOrEditApp-visible-to-students"
                  ariaLabel="link visible to students"
                  selected={!app.hiddenFromStudents}
                  onSelected={() => {
                    // Update add type
                    const updatedApp: App = {
                      ...app,
                      hiddenFromStudents: false,
                    };

                    // Update app
                    dispatch({
                      type: ActionType.UpdateApp,
                      app: updatedApp,
                    });
                  }}
                  small
                />
                <RadioButton
                  text="Hidden from Students"
                  id="AddOrEditApp-hidden-from-students"
                  ariaLabel="link hidden from students"
                  selected={app.hiddenFromStudents}
                  onSelected={() => {
                    // Update add type
                    const updatedApp: App = {
                      ...app,
                      hiddenFromStudents: true,
                    };

                    // Update app
                    dispatch({
                      type: ActionType.UpdateApp,
                      app: updatedApp,
                    });
                  }}
                  small
                />
              </ButtonInputGroup>
            </div>
          )}

          {/* Enable LTI */}
          {(app.addType === AddType.EnableLTI) && (
            <div>
              <div className="input-group">
                <span
                  className="AddOrEditApp-input-label input-group-text"
                  id="AddOrEditApp-form-app-ids-label"
                >
                  App Ids
                </span>
                <textarea
                  id="AddOrEditApp-form-app-ids-input"
                  className="form-control"
                  placeholder="Required app id(s) to enable (one app id per line)"
                  aria-describedby="AddOrEditApp-form-app-ids-label"
                  value={(
                    app.appIds
                      .join('\n')
                      .split('\n')
                      .map((line) => {
                        return (
                          line === '-1'
                            ? ''
                            : line
                        );
                      })
                      .join('\n')
                  )}
                  rows={6}
                  onChange={(e) => {
                    // Save info to app object
                    app.appIds = (
                      e.target.value
                        .split('\n')
                        .map((id) => {
                          if (Number.isNaN(Number.parseInt(id.trim(), 10))) {
                            return -1;
                          }

                          return (
                            id.trim().length === 0
                              ? -1
                              : Number.parseInt(id.trim(), 10)
                          );
                        })
                    );

                    // Update app
                    dispatch({
                      type: ActionType.UpdateApp,
                      app,
                    });
                  }}
                />
              </div>
            </div>
          )}

          {/* By ID */}
          {(app.addType === AddType.InstallLTIById) && (
            <div>
              <div className="input-group mb-1">
                <span
                  className="AddOrEditApp-input-label input-group-text"
                  id="AddOrEditApp-form-client-id-label"
                >
                  Client ID
                </span>
                <input
                  id="AddOrEditApp-form-client-id-input"
                  type="text"
                  className="form-control"
                  placeholder="Required LTI v1.3 Client ID from HUIT"
                  aria-describedby="AddOrEditApp-form-client-id-label"
                  value={app.clientId === INPUT_PLACEHOLDER ? '' : (app.clientId ?? '')}
                  onChange={(e) => {
                    // Save info to app object
                    app.clientId = e.target.value.trim();

                    // Update app
                    dispatch({
                      type: ActionType.UpdateApp,
                      app,
                    });
                  }}
                />
              </div>
              <div className="input-group mb-1">
                <span
                  className="AddOrEditApp-input-label input-group-text"
                  id="AddOrEditApp-form-client-launch-url-label"
                >
                  Launch URL
                </span>
                <input
                  id="AddOrEditApp-form-client-launch-url-input"
                  type="text"
                  className="form-control"
                  placeholder="Required URL in app's configuration that is used for launches"
                  aria-describedby="AddOrEditApp-form-client-launch-url-label"
                  value={app.launchURL === INPUT_PLACEHOLDER ? '' : (app.launchURL ?? '')}
                  onChange={(e) => {
                    // Save info to app object
                    app.launchURL = e.target.value.trim();

                    // Update app
                    dispatch({
                      type: ActionType.UpdateApp,
                      app,
                    });
                  }}
                />
              </div>
            </div>
          )}

          {/* By Metadata */}
          {(app.addType === AddType.InstallLTIByMetadata) && (
            <div>
              <div className="input-group mb-1">
                <span
                  className="AddOrEditApp-input-label input-group-text"
                  id="AddOrEditApp-form-consumer-key-label"
                >
                  Key
                </span>
                <input
                  id="AddOrEditApp-form-consumer-key-input"
                  type="text"
                  className="form-control"
                  placeholder="Required LTI consumer key"
                  aria-describedby="AddOrEditApp-form-consumer-key-label"
                  value={app.consumerKey === INPUT_PLACEHOLDER ? '' : (app.consumerKey ?? '')}
                  onChange={(e) => {
                    // Save info to app object
                    app.consumerKey = e.target.value.trim();

                    // Update app
                    dispatch({
                      type: ActionType.UpdateApp,
                      app,
                    });
                  }}
                />
              </div>
              <div className="input-group mb-1">
                <span
                  className="AddOrEditApp-input-label input-group-text"
                  id="AddOrEditApp-form-consumer-secret-label"
                >
                  Secret
                </span>
                <input
                  type="text"
                  id="AddOrEditApp-form-consumer-secret-input"
                  className="form-control"
                  placeholder="Required LTI consumer secret (also called shared secret)"
                  aria-describedby="AddOrEditApp-form-consumer-secret-label"
                  value={app.consumerSecret === INPUT_PLACEHOLDER ? '' : (app.consumerSecret ?? '')}
                  onChange={(e) => {
                    // Save info to app object
                    app.consumerSecret = e.target.value.trim();

                    // Update app
                    dispatch({
                      type: ActionType.UpdateApp,
                      app,
                    });
                  }}
                />
              </div>
              <div className="input-group">
                <span
                  className="AddOrEditApp-input-label input-group-text"
                  id="AddOrEditApp-form-lti-xml-label"
                >
                  XML
                </span>
                <textarea
                  id="AddOrEditApp-form-lti-xml-input"
                  className="form-control"
                  placeholder="Required LTI XML cartridge"
                  aria-describedby="AddOrEditApp-form-lti-xml-label"
                  value={app.xml === INPUT_PLACEHOLDER ? '' : (app.xml ?? '')}
                  rows={6}
                  onChange={(e) => {
                    // Save info to app object
                    app.xml = e.target.value;

                    // Update app
                    dispatch({
                      type: ActionType.UpdateApp,
                      app,
                    });
                  }}
                />
              </div>
            </div>
          )}

          {/* Manually */}
          {(app.addType === AddType.Manual) && (
            <div>
              <div className="input-group">
                <span
                  className="AddOrEditApp-input-label input-group-text"
                  id="AddOrEditApp-form-start-link-label"
                >
                  Start Link
                </span>
                <input
                  id="AddOrEditApp-form-start-link-input"
                  type="text"
                  className="form-control"
                  placeholder="Optional start link for admins who install this app manually"
                  aria-describedby="AddOrEditApp-form-start-link-label"
                  value={app.startLink === INPUT_PLACEHOLDER ? '' : (app.startLink ?? '')}
                  onChange={(e) => {
                    // Save info to app object
                    app.startLink = e.target.value.trim();

                    // Update app
                    dispatch({
                      type: ActionType.UpdateApp,
                      app,
                    });
                  }}
                />
              </div>
              <div className="mb-1">
                <small>
                  In the start link, can use
                  {' '}
                  <em>
                    $&#123;courseId&#125;
                  </em>
                  {' '}
                  as a placeholder for the Canvas courseId.
                </small>
              </div>
              <div className="input-group">
                <span
                  className="AddOrEditApp-input-label input-group-text"
                  id="AddOrEditApp-form-manual-instructions-label"
                >
                  <div>
                    <div>
                      Instructions
                    </div>
                    <div>
                      (markdown)
                    </div>
                  </div>
                </span>
                <textarea
                  id="AddOrEditApp-form-manual-instructions-input"
                  className="form-control"
                  placeholder="Optional instructions for admins who install this app manually"
                  aria-describedby="AddOrEditApp-form-manual-instructions-label"
                  value={app.instructions === INPUT_PLACEHOLDER ? '' : (app.instructions ?? '')}
                  rows={6}
                  onChange={(e) => {
                    // Save info to app object
                    app.instructions = e.target.value;

                    // Update app
                    dispatch({
                      type: ActionType.UpdateApp,
                      app,
                    });
                  }}
                />
              </div>
            </div>
          )}

          {/* Already Added */}
          {(app.addType === AddType.AlreadyAdded) && (
            <div>
              {/* Notice before add */}
              <div className="input-group mb-0">
                <span
                  className="AddOrEditApp-input-label input-group-text"
                  id="AddOrEditApp-form-message-how-to-find-it-label"
                  style={{ width: '7em' }}
                >
                  <div>
                    <div>
                      How to
                    </div>
                    <div>
                      Find It
                    </div>
                    <div>
                      (markdown)
                    </div>
                  </div>
                </span>
                <textarea
                  id="AddOrEditApp-form-message-how-to-find-it"
                  className="form-control"
                  placeholder="Message to show when a user asks for how to find the tool"
                  aria-describedby="AddOrEditApp-form-message-how-to-find-it-label"
                  value={app.howToFindIt === INPUT_PLACEHOLDER ? '' : app.howToFindIt}
                  rows={6}
                  onChange={(e) => {
                    app.howToFindIt = e.target.value;
                    dispatch({
                      type: ActionType.UpdateApp,
                      app,
                    });
                  }}
                />
              </div>
            </div>
          )}

          {addAppInvalid && (
            <div className="small text-danger">
              Please fill out required fields above.
            </div>
          )}
        </Drawer>

        {/* Buttons */}
        <div className="text-center mt-2">
          <button
            type="button"
            id="AddOrEditApp-save-changes-button"
            className="btn btn-primary btn-lg me-1"
            aria-label="save changes"
            onClick={() => {
              if (validationFailed) {
                return alert(
                  'Complete Required Fields First',
                  'To continue, you need to complete all required fields.',
                );
              }
              save();
            }}
          >
            <FontAwesomeIcon
              icon={faSave}
              className="me-1"
            />
            Save
          </button>
          <button
            type="button"
            id="AddOrEditApp-cancel-button"
            className="btn btn-secondary btn-lg me-1"
            aria-label="save changes"
            onClick={cancel}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div className="alert alert-light text-black">
      {body}
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AddOrEditApp;
