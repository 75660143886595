/**
 * Modal that guides the user through the process of adding an app
 * @author Jackson Parsells
 */

// Import React
import React, { useReducer, useEffect } from 'react';

// Import dce-reactkit
import { waitMs } from 'dce-reactkit';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

type Props = {
  // children represents the body content of the modal, the content that will
  // be displayed in the primary box of it
  children: React.ReactNode,
  // confirm content is a message to show if the current view requires a
  // button for continuation
  confirmContent?: React.ReactNode,
  // wait for confirm is a time in milliseconds that will delay the
  // appearance of a continuation button
  waitForConfirmMS?: number,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = (
  | {
    // button is shown is utilized to toggle the optional button component
    // on and off; this should always be true except in the case of a
    // wait for confirm value greater than 0
    buttonIsShown: boolean,
  }
);

/* ------------- Actions ------------ */

enum ActionType {
  ShowButton = 'show-button',
  HideButton = 'hide-button',
}

type Action = (
  | {
    type: (
      | ActionType.ShowButton
      | ActionType.HideButton
    )
  }
);

/**
 * This reducer is utilized exclusively to update the button visibility in the
 * modal based on the value passed in to wait for confirm prop
 * @author Jackson Parsells
 * @param state the current state of the popup, refers to if the button is
 *              already being displayed or not
 * @param action the action to modify the visibility
 * @returns a new state with the new visibility
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ShowButton: {
      return {
        buttonIsShown: true,
      };
    }
    case ActionType.HideButton: {
      return {
        buttonIsShown: false,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

/**
 * Generate the content for the popup box
 * @author Jackson Parsells
 * @returns JSX for the popup box
 */
const AddModalBox: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  // destructure props
  const {
    children,
    confirmContent,
    waitForConfirmMS,
  } = props;

  const initialState: State = {
    buttonIsShown: !waitForConfirmMS,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  // destructure state
  const {
    buttonIsShown,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  // wait for the waitForConfirmMS time before hiding the button
  useEffect(
    () => {
      (async () => {
        if (!buttonIsShown) {
          await waitMs(waitForConfirmMS);

          dispatch({
            type: ActionType.ShowButton,
          });
        }
      })();
    },
    [waitForConfirmMS],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div className="AddModalBox-add-popup-box">
      <div className="AddModalBox-add-popup-box-body">
        {children}
      </div>
      {(buttonIsShown && confirmContent) && (
        <div className="AddModalBox-add-popup-box-confirm">
          {confirmContent}
        </div>
      )}
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

export default AddModalBox;
