// Import react
import React, { useReducer, useEffect } from 'react';

// Import caccl
import { getStatus } from 'caccl/client';
import LaunchInfo from 'caccl/types/LaunchInfo';

// Import dce-reactkit
import {
  showFatalError,
  LoadingSpinner,
  visitServerEndpoint,
} from 'dce-reactkit';

// Import shared types
import Service from './shared/types/Service';

// Import shared components
import ContentContainer from './shared/ContentContainer';

// Import other components
import Header from './shared/Header';

// Import services
import services from './services';

// Import other panels
import AdminPanel from './AdminPanel';

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Loading screen
  Loading = 'loading',
  // Show service
  ShowService = 'show-service',
}

/* -------- State Definition -------- */

type State = (
  | {
    // Current view
    view: View.Loading,
  }
  | {
    // Current view
    view: View.ShowService,
    // The service to show
    service: Service,
    // Launch info
    launchInfo: LaunchInfo,
    // True if admin panel is visible
    adminPanelVisible: boolean,
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Finish loading
  FinishLoading = 'finish-loading',
  // Move to the next service
  NextService = 'next-service',
  // Show the admin panel
  ShowAdminPanel = 'show-admin-panel',
  // Hide the admin panel
  HideAdminPanel = 'hide-admin-panel',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.FinishLoading,
    // Launch info
    launchInfo: LaunchInfo,
  }
  | {
    // Action type
    type: (
      | ActionType.NextService
      | ActionType.ShowAdminPanel
      | ActionType.HideAdminPanel
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  // Actions that can only happen if there is a service shown
  if (state.view === View.ShowService) {
    switch (action.type) {
      case ActionType.NextService: {
        // Figure out which service is currently being shown
        const currServiceIndex = services.indexOf(state.service);

        // Check if we can continue
        if (currServiceIndex === services.length - 1) {
          // Ran out of services. Show fatal error
          showFatalError('Ran out of services. Contact support.');
          return state;
        }

        // Go to the next service
        return {
          ...state,
          service: services[currServiceIndex + 1],
        };
      }
      case ActionType.ShowAdminPanel: {
        return {
          ...state,
          adminPanelVisible: true,
        };
      }
      case ActionType.HideAdminPanel: {
        return {
          ...state,
          adminPanelVisible: false,
        };
      }
    }
  }

  // Actions that can happen at any point
  switch (action.type) {
    case ActionType.FinishLoading: {
      return {
        view: View.ShowService,
        launchInfo: action.launchInfo,
        service: services[0],
        adminPanelVisible: false,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const Client: React.FC<{}> = () => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    view: View.Loading,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      (async () => {
        // Get app status
        try {
          const status = await getStatus();

          // Make sure user has launched via LTI
          if (!status.launched) {
            showFatalError(
              'Go to Canvas and reopen this app.',
              'Not Logged In',
            );
            return;
          }

          // Make sure user has authorized
          if (!status.authorized) {
            showFatalError(
              'Go to Canvas, reopen and authorize this app.',
              'Authorization Expired',
            );
            return;
          }

          // Modify launch info if the user is not an approved admin
          if (status.launchInfo.isAdmin) {
            // Check if the user is approved
            const isApproved = await visitServerEndpoint({
              path: '/api/admin/shared/current-user-is-approved-admin',
              method: 'GET',
            });

            // Overwrite isAdmin and make it false
            if (!isApproved) {
              status.launchInfo.isAdmin = false;
            }
          }

          // Finish loading
          dispatch({
            type: ActionType.FinishLoading,
            launchInfo: status.launchInfo,
          });
        } catch (err) {
          showFatalError(err);
        }
      })();
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;

  /* ------------- Loading ------------ */

  if (view === View.Loading) {
    body = (
      <LoadingSpinner />
    );
  }

  /* ---------- Show Service ---------- */

  if (view === View.ShowService) {
    const {
      service,
      launchInfo,
      adminPanelVisible,
    } = state;

    // Admin panel
    if (adminPanelVisible) {
      body = (
        <AdminPanel
          onExitAdminPanel={() => {
            dispatch({
              type: ActionType.HideAdminPanel,
            });
          }}
        />
      );
    } else {
      // Service
      body = (
        <>
          <Header
            courseName={launchInfo.contextLabel}
            service={service}
            onShowAdminPanelClicked={(
              launchInfo.isAdmin
                ? () => {
                  dispatch({
                    type: ActionType.ShowAdminPanel,
                  });
                }
                : undefined
            )}
          />
          <ContentContainer>
            <service.component
              launchInfo={launchInfo}
              goToNextService={() => {
                dispatch({
                  type: ActionType.NextService,
                });
              }}
            />
          </ContentContainer>
        </>
      );
    }
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div className="Client">
      {body}
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default Client;
