/**
 * Edit categories
 * @author Yuen Ler Chow
 */

// Import React
import React, { useReducer, useEffect } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

// Import react kit
import {
  showFatalError,
  LoadingSpinner,
  visitServerEndpoint,
  TabBox,
  confirm,
} from 'dce-reactkit';

// Import shared types
import TrainingCategory from '../../shared/types/from-server/stored/TrainingCategory';

// Import other components
import AddTrainingCategory from './AddTrainingCategory';

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // True if loading
  loading: boolean,
  // List of training categories
  trainingCategories: TrainingCategory[],
  // True if adding a new training category
  adding: boolean,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Finish loading
  FinishLoading = 'FinishLoading',
  // Show training adder
  ShowTrainingCategoryAdder = 'ShowTrainingCategoryAdder',
  // Finish adding a training category
  FinishAdd = 'FinishAdd',
  // Start deletion process
  StartDelete = 'StartDelete',
  // Finish deletion process
  FinishDelete = 'FinishDelete',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.FinishLoading,
    // training category list
    trainingCategories: TrainingCategory[],
  }
  | {
    // Action type
    type: ActionType.ShowTrainingCategoryAdder,
    // Training category being edited
    trainingCategory: TrainingCategory,
  }
  | {
    // Action type
    type: ActionType.FinishAdd,
    // training category that was added
    trainingCategory?: TrainingCategory,
  }
  | {
    // Action type
    type: ActionType.FinishDelete,
    // training category that was deleted
    trainingCategory: TrainingCategory,
  }
  | {
    // Action type
    type: (
      | ActionType.ShowTrainingCategoryAdder
      | ActionType.StartDelete
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.FinishLoading: {
      return {
        ...state,
        loading: false,
        trainingCategories: action.trainingCategories,
      };
    }
    case ActionType.ShowTrainingCategoryAdder: {
      return {
        ...state,
        adding: true,
      };
    }
    case ActionType.FinishAdd: {
      // Handle cancel
      const finishedTraining = action.trainingCategory;
      if (!finishedTraining) {
        return {
          ...state,
          adding: false,
        };
      }

      // Create an updated list of training categories
      const updatedTrainingCategories: TrainingCategory[] = [...state.trainingCategories, finishedTraining];

      // Update the state
      return {
        ...state,
        adding: false,
        trainingCategories: updatedTrainingCategories,
      };
    }
    case ActionType.StartDelete: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.FinishDelete: {
      return {
        ...state,
        loading: false,
        trainingCategories: state.trainingCategories.filter((category) => {
          return (category.webName !== action.trainingCategory.webName);
        }),
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const TrainingCategoriesManagerPanel: React.FC<{}> = () => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    loading: true,
    trainingCategories: [],
    adding: false,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    loading,
    trainingCategories,
    adding,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Delete a training category
   * @author Yuen Ler Chow
   * @param category the training category to delete
   */
  const deleteTrainingCategory = async (category: TrainingCategory) => {
    // Confirm once

    const confirmed = await confirm(
      'Remove training category?',
      'Are you sure? You will need to reenter the web name and name of the training category if you want to add it again.',
    );

    if (!confirmed) {
      return;
    }

    // Remove the training category
    try {
      // Start loader
      dispatch({
        type: ActionType.StartDelete,
      });

      // Perform deletion
      await visitServerEndpoint({
        path: `/api/admin/services/trainings-list/training-categories/${category.webName}`,
        method: 'DELETE',
      });

      // Finish loader
      dispatch({
        type: ActionType.FinishDelete,
        trainingCategory: category,
      });
    } catch (err) {
      return showFatalError(err);
    }
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Yuen Ler Chow
   */
  useEffect(
    () => {
      (async () => {
        // Load list of training categories
        try {
          // Get list of trainings
          const categories = await visitServerEndpoint({
            path: '/api/admin/services/trainings-list/training-categories',
            method: 'GET',
          });

          // Save loaded trainings
          dispatch({
            type: ActionType.FinishLoading,
            trainingCategories: categories,
          });
        } catch (err) {
          return showFatalError(err);
        }
      })();
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;

  /* ------------- Loading ------------ */

  if (loading) {
    body = (
      <LoadingSpinner />
    );
  }

  /* ------------ Training Category List ------------ */

  if (!loading && !adding) {
    // Create body
    body = (
      <div>
        <TabBox
          title="Training Categories"
        >
          {/* List of Training Categories */}
          {trainingCategories.map((category) => {
            return (
              <div
                key={category.webName}
                className="alert alert-secondary p-2 mb-2 d-flex align-items-center justify-content-center mb-1"
              >
                <div className="flex-grow-1">
                  <h4 className="m-0">
                    <span className="fw-bold">{category.name}</span>
                    <span className="small">
                      {' '}
                      (
                      {category.webName}
                      )
                    </span>
                  </h4>
                </div>
                {/* Buttons */}
                <div className="d-flex align-items-center">
                  <button
                    type="button"
                    id={`TrainingCategoriesManagerPanel-remove-category-with-id-${category.webName}`}
                    className="btn btn-secondary me-1"
                    aria-label={`remove training category: ${category.name}`}
                    onClick={() => {
                      deleteTrainingCategory(category);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                    />
                    <span className="d-none d-md-inline ms-1">
                      Remove
                    </span>
                  </button>

                </div>
              </div>
            );
          })}

          {/* Add Training Category Button */}
          <div className="d-grid">
            <button
              type="button"
              id="TrainingCategoriesManagerPanel-add-category"
              className="btn btn-lg btn-primary"
              aria-label="add a new category to the list of available categories"
              onClick={() => {
                dispatch({
                  type: ActionType.ShowTrainingCategoryAdder,
                });
              }}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className="me-2"
              />
              Add Training Category
            </button>
          </div>
        </TabBox>
      </div>
    );
  }

  /* --------- Create category -------- */

  if (!loading && (adding)) {
    body = (
      <AddTrainingCategory
        onFinished={(trainingCategory?: TrainingCategory) => {
          dispatch({
            type: ActionType.FinishAdd,
            trainingCategory,
          });
        }}
      />
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div>
      {body}
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default TrainingCategoriesManagerPanel;
