// Import shared types
import App from '../types/from-server/stored/App';

/**
 * Divide apps into categories
 * @author Gabe Abrams
 */
const categorizeApps = (apps: App[]): {
  category: string,
  appsInCategory: App[],
}[] => {
  // Divide into categories
  const categoryToApps: { [k: string]: App[] } = {};
  apps.forEach((app) => {
    // Initialize app list
    if (!categoryToApps[app.category]) {
      categoryToApps[app.category] = [];
    }

    // Add to list
    categoryToApps[app.category].push(app);
  });

  // Sort categories
  const categories = Object.keys(categoryToApps);
  categories.sort();

  // Put into return type format
  return categories.map((category) => {
    return {
      category,
      appsInCategory: categoryToApps[category],
    };
  });
};

export default categorizeApps;
