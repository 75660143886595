/**
 * Types of placement in Canvas
 * @author Gabe Abrams
 */
enum Placement {
  Navigation = 'navigation',
  RichContentEditor = 'rich-content-editor',
  ExternalAssignment = 'external-assignment',
}

export default Placement;
