/**
 * Manager for the list of approved admins
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer, useEffect } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  alert,
  LoadingSpinner,
  showFatalError,
  TabBox,
  visitServerEndpoint,
} from 'dce-reactkit';

// Import shared types
import ApprovedAdmin from '../../shared/types/from-server/stored/ApprovedAdmin';

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = (
  {
    // True if loading
    loading: boolean,
    // List of hardcoded approved admins
    hardcodedApprovedAdmins: ApprovedAdmin[],
    // List of approved admins (in database)
    approvedAdmins: ApprovedAdmin[],
    // Canvas id being typed for the new user
    userIdText: string,
    // First name being typed for the new user
    userFirstNameText: string,
    // Last name being typed for the new user
    userLastNameText: string,
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Show the loading screen
  StartLoading = 'start-loading',
  // Finish initial loading
  FinishInitialLoading = 'finish-initial-loading',
  // Add an approved admin to the list
  AddApprovedAdmin = 'add-approved-admin',
  // Remove an approved admin from the list
  RemoveApprovedAdmin = 'remove-approved-admin',
  // Update the user id text
  UpdateUserIdText = 'update-user-id-text',
  // Update the user first name text
  UpdateUserFirstNameText = 'update-user-first-name-text',
  // Update the user last name text
  UpdateUserLastNameText = 'update-user-last-name-text',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.FinishInitialLoading,
    // List of hardcoded approved admins
    hardcodedApprovedAdmins: ApprovedAdmin[],
    // List of approved admins in db
    approvedAdmins: ApprovedAdmin[],
  }
  | {
    // Action type
    type: (
      | ActionType.AddApprovedAdmin
      | ActionType.RemoveApprovedAdmin
    ),
    // The admin to modify
    approvedAdmin: ApprovedAdmin,
  }
  | {
    // Action type
    type: (
      | ActionType.UpdateUserIdText
      | ActionType.UpdateUserFirstNameText
      | ActionType.UpdateUserLastNameText
    ),
    // The new text
    newText: string,
  }
  | {
    // Action type
    type: (
      | ActionType.StartLoading
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.StartLoading: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.FinishInitialLoading: {
      return {
        ...state,
        loading: false,
        hardcodedApprovedAdmins: action.hardcodedApprovedAdmins,
        approvedAdmins: action.approvedAdmins,
      };
    }
    case ActionType.AddApprovedAdmin: {
      const { approvedAdmins } = state;
      approvedAdmins.push(action.approvedAdmin);
      return {
        ...state,
        approvedAdmins,
        loading: false,
        userIdText: '',
        userFirstNameText: '',
        userLastNameText: '',
      };
    }
    case ActionType.RemoveApprovedAdmin: {
      const approvedAdmins = state.approvedAdmins.filter((approvedAdmin) => {
        return (approvedAdmin.userId !== action.approvedAdmin.userId);
      });
      return {
        ...state,
        approvedAdmins,
        loading: false,
      };
    }
    case ActionType.UpdateUserIdText: {
      return {
        ...state,
        userIdText: action.newText,
      };
    }
    case ActionType.UpdateUserFirstNameText: {
      return {
        ...state,
        userFirstNameText: action.newText,
      };
    }
    case ActionType.UpdateUserLastNameText: {
      return {
        ...state,
        userLastNameText: action.newText,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const ApprovedAdminPanel: React.FC<{}> = () => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    loading: true,
    hardcodedApprovedAdmins: [],
    approvedAdmins: [],
    userIdText: '',
    userFirstNameText: '',
    userLastNameText: '',
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    loading,
    hardcodedApprovedAdmins,
    approvedAdmins,
    userIdText,
    userFirstNameText,
    userLastNameText,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Add an approved admin to the list
   * @author Gabe Abrams
   */
  const addApprovedAdmin = async () => {
    // Validate
    if (
      // Empty text
      userIdText.trim().length === 0
      // Not a number
      || Number.isNaN(Number.parseInt(userIdText.trim(), 10))
    ) {
      return alert(
        'Add a User Id',
        'Please add a valid CanvasId for the approved admin.',
      );
    }
    if (userFirstNameText.trim().length === 0) {
      return alert(
        'Add a User First Name',
        'Please add a first name for the approved admin.',
      );
    }
    if (userLastNameText.trim().length === 0) {
      return alert(
        'Add a User Last Name',
        'Please add a last name for the approved admin.',
      );
    }

    // Create the approved admin
    const approvedAdmin: ApprovedAdmin = {
      userId: Number.parseInt(userIdText, 10),
      userFirstName: userFirstNameText.trim(),
      userLastName: userLastNameText.trim(),
    };

    // Show loading indicator
    dispatch({ type: ActionType.StartLoading });

    // Add to database
    try {
      await visitServerEndpoint({
        path: '/api/admin/shared/approved-admins',
        method: 'POST',
        params: {
          approvedAdmin: JSON.stringify(approvedAdmin),
        },
      });
    } catch (err) {
      return showFatalError(err);
    }

    // Update state
    dispatch({
      type: ActionType.AddApprovedAdmin,
      approvedAdmin,
    });
  };

  /**
   * Remove an approved admin from the list
   * @author Gabe Abrams
   * @param approvedAdmin the approved admin to remove
   */
  const removeApprovedAdmin = async (approvedAdmin: ApprovedAdmin) => {
    // Show loading indicator
    dispatch({ type: ActionType.StartLoading });

    // Add to database
    try {
      await visitServerEndpoint({
        path: `/api/admin/shared/approved-admins/admins/${approvedAdmin.userId}`,
        method: 'DELETE',
      });
    } catch (err) {
      return showFatalError(err);
    }

    // Update state
    dispatch({
      type: ActionType.RemoveApprovedAdmin,
      approvedAdmin,
    });
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Load the list of approved admins
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      (async () => {
        try {
          const info = await visitServerEndpoint({
            path: '/api/admin/shared/approved-admins',
            method: 'GET',
          });

          return dispatch({
            type: ActionType.FinishInitialLoading,
            hardcodedApprovedAdmins: info.hardcodedApprovedAdmins,
            approvedAdmins: info.approvedAdmins,
          });
        } catch (err) {
          return showFatalError(err);
        }
      })();
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;

  /* ------------- Loading ------------ */

  if (loading) {
    // Create body
    body = (
      <LoadingSpinner />
    );
  }

  /* ------------- Manager ------------ */

  if (!loading) {
    // Create approved admin elements
    const approvedAdminElems: React.ReactNode[] = [];
    [
      {
        admins: hardcodedApprovedAdmins,
        isHardcoded: true,
      },
      {
        admins: approvedAdmins,
        isHardcoded: false,
      },
    ].forEach(({ admins, isHardcoded }) => {
      admins.forEach((admin) => {
        approvedAdminElems.push(
          <div
            key={admin.userId}
            className="alert alert-secondary p-1 mb-1 d-flex align-items-center justify-content-center"
          >
            <div className="flex-grow-1">
              <h3 className="m-0">
                {admin.userFirstName}
                {' '}
                {admin.userLastName}
                {' '}
                <span className="fw-normal">
                  (
                  {admin.userId}
                  )
                </span>
              </h3>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-secondary"
                aria-label={`remove ${admin.userFirstName} ${admin.userLastName} from the list of approved admins`}
                onClick={() => {
                  if (isHardcoded) {
                    alert(
                      'Not Allowed',
                      'This admin\'s access is locked. Please contact Gabe or another tool developer to have this user\'s access removed.',
                    );
                  } else {
                    removeApprovedAdmin(admin);
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  className="me-2"
                />
                Remove
              </button>
            </div>
          </div>,
        );
      });
    });

    // Create body
    body = (
      <div>
        {/* Approved Admins */}
        <TabBox title="Approved Admins">
          {/* List */}
          {approvedAdminElems}

          {/* Add Form */}
          <div className="mt-2">
            <h5 className="m-0">
              Add an approved admin:
            </h5>

            <div className="d-flex align-items-center">
              {/* User id */}
              <div className="me-2">
                <div className="input-group">
                  <span className="input-group-text">
                    Canvas&nbsp;Id
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="e.g. 12345"
                    size={10}
                    aria-label="the canvas id for the new approved admin"
                    onChange={(e) => {
                      dispatch({
                        type: ActionType.UpdateUserIdText,
                        newText: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              {/* User first name */}
              <div className="me-2">
                <div className="input-group">
                  <span className="input-group-text">
                    First
                    <span className="d-none d-lg-inline">
                      &nbsp;Name
                    </span>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="e.g. Gabe"
                    size={12}
                    aria-label="the first name for the new approved admin"
                    onChange={(e) => {
                      dispatch({
                        type: ActionType.UpdateUserFirstNameText,
                        newText: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              {/* User last name */}
              <div className="me-2">
                <div className="input-group">
                  <span className="input-group-text">
                    Last
                    <span className="d-none d-lg-inline">
                      &nbsp;Name
                    </span>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="e.g. Abrams"
                    size={12}
                    aria-label="the last name for the new approved admin"
                    onChange={(e) => {
                      dispatch({
                        type: ActionType.UpdateUserLastNameText,
                        newText: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              {/* Add Button */}
              <div>
                <button
                  type="button"
                  className="btn btn-primary"
                  aria-label="add the new approved admin"
                  onClick={() => {
                    addApprovedAdmin();
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="d-none d-md-inline me-2"
                  />
                  Add
                  <span className="d-none d-lg-inline">
                    {' '}
                    Admin
                  </span>
                </button>
              </div>
            </div>
          </div>
        </TabBox>
      </div>
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div>
      {/* Header */}
      <h2 className="text-center">
        Approved Admins Manager
      </h2>

      {/* Add Body */}
      {body}
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default ApprovedAdminPanel;
