/**
 * Panel for adding a training category
 * @author Yuen Ler Chow
 */

// Import React
import React, { useReducer } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSave,
} from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  LoadingSpinner,
  showFatalError,
  alert,
  visitServerEndpoint,
} from 'dce-reactkit';

// Import shared types
import TrainingCategory from '../../../shared/types/from-server/stored/TrainingCategory';

// Import other helpers
import isValidWebName from './isValidWebName';

// Import constants
import INPUT_PLACEHOLDER from './INPUT_PLACEHOLDER';

// Import style
import './style.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  /**
   * Handler for when the user finishes adding the training category
   * (if no training category is returned, process was cancelled)
   * @param trainingCategory the training category that was just created
   */
  onFinished: (trainingCategory?: TrainingCategory) => void,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // The training category's current state
  trainingCategory: TrainingCategory,
  // True if currently saving
  saving: boolean,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Update the training category
  UpdateTrainingCategory = 'UpdateTrainingCategory',
  // Start the save spinner
  StartSave = 'StartSave',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.UpdateTrainingCategory,
    // New state of the trainingCategory
    trainingCategory: TrainingCategory,
  }
  | {
    // Action type
    type: (
      | ActionType.StartSave
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Yuen Ler Chow
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.UpdateTrainingCategory: {
      return {
        ...state,
        trainingCategory: action.trainingCategory,
      };
    }
    case ActionType.StartSave: {
      return {
        ...state,
        saving: true,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AddTrainingCategory: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    onFinished,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    trainingCategory: {
      name: '',
      webName: '',
    },
    saving: false,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    trainingCategory,
    saving,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Save changes to the training category and then finish
   * @author Yuen Ler Chow
   */
  const save = async () => {
    // Start the save loading indicator
    dispatch({ type: ActionType.StartSave });

    // Send to server
    try {
      await visitServerEndpoint({
        path: '/api/admin/services/trainings-list/training-categories',
        method: 'POST',
        params: {
          trainingCategory: JSON.stringify(trainingCategory),
        },
      });

      // Finish
      onFinished(trainingCategory);
    } catch (err) {
      return showFatalError(err);
    }
  };

  /**
   * Cancel and return without saving
   * @author Gabe Abrams
   */
  const cancel = async () => {
    onFinished(undefined);
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body
  let body: React.ReactNode;

  /* ------------- Loading ------------ */

  if (saving) {
    body = (
      <LoadingSpinner />
    );
  }

  /* -------------- Form -------------- */

  if (!saving) {
    // Validation
    const nameInvalid = (trainingCategory.name.trim().length < 5);
    const webNameInvalid = (trainingCategory.webName.trim().length < 5);

    // Boolean for all of validation
    const validationFailed = (
      nameInvalid
      || webNameInvalid
    );

    // UI
    body = (
      <div>
        {/* Name */}
        <div className="mb-2">
          <div className="input-group">
            <span
              className="AddTrainingCategory-input-label input-group-text"
              id="AddTrainingCategory-form-name-label"
            >
              Name
            </span>
            <input
              id="AddTrainingCategory-form-name-input"
              type="text"
              className="form-control"
              placeholder="Add a human-readable name for the training category"
              aria-describedby="AddTrainingCategory-form-name-label"
              value={trainingCategory.name === INPUT_PLACEHOLDER ? '' : trainingCategory.name}
              onChange={(e) => {
                trainingCategory.name = (
                  e.target.value
                    .replace(/[^a-zA-Z0-9\s(),-]/g, '')
                    .substring(0, 50)
                );
                dispatch({
                  type: ActionType.UpdateTrainingCategory,
                  trainingCategory,
                });
              }}
            />
          </div>
          {nameInvalid && (
            <div className="small text-danger">
              Name is required and must be at least 5 chars.
            </div>
          )}
        </div>

        {/* ID */}
        <div className="mb-2">
          <div className="input-group">
            <span
              className="AddTrainingCategory-input-label input-group-text"
              id="AddTrainingCategory-form-id-label"
            >
              Web name
            </span>
            <input
              id="AddTrainingCategory-form-id-input"
              type="text"
              className="form-control"
              placeholder="Required machine-readable id"
              aria-describedby="AddTrainingCategory-form-id-label"
              value={trainingCategory.webName === INPUT_PLACEHOLDER ? '' : trainingCategory.webName}
              onChange={(e) => {
                trainingCategory.webName = (
                  e.target.value
                    .trim()
                    .toLowerCase()
                );
                dispatch({
                  type: ActionType.UpdateTrainingCategory,
                  trainingCategory,
                });
              }}
            />
          </div>
          {webNameInvalid && (
            <div className="small text-danger">
              Web name is required and must be at least 5 chars.
            </div>
          )}
        </div>

        {/* Buttons */}
        <div className="text-center mt-2">
          <button
            type="button"
            id="AddTrainingCategory-save-changes-button"
            className="btn btn-primary btn-lg me-1"
            aria-label="save changes"
            onClick={async () => {
              if (validationFailed) {
                return alert(
                  'Complete Required Fields First',
                  'To continue, you need to complete all required fields.',
                );
              }

              const validWebName = await isValidWebName(trainingCategory.webName);
              if (!validWebName) {
                return alert(
                  'Invalid Web Name',
                  'This web name does not seem to correspond to a valid Trumba calendar. Refer to this link to find the correct web name: https://www.trumba.com/help/pubcalname.aspx',
                );
              }
              save();
            }}
          >
            <FontAwesomeIcon
              icon={faSave}
              className="me-1"
            />
            Save
          </button>
          <button
            type="button"
            id="AddTrainingCategory-cancel-button"
            className="btn btn-secondary btn-lg me-1"
            aria-label="save changes"
            onClick={cancel}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div className="alert alert-light text-black">
      {body}
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AddTrainingCategory;
