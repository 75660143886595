/**
 * Edit apps in app store
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer, useEffect } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

// Import react kit
import {
  showFatalError,
  LoadingSpinner,
  visitServerEndpoint,
  TabBox,
  confirm,
} from 'dce-reactkit';

// Import shared types
import App from '../../shared/types/from-server/stored/App';
import AddOrEditApp from './AddOrEditApp';
import IconPreview from '../../shared/IconPreview';
import categorizeApps from '../../shared/helpers/categorizeApps';

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // True if loading
  loading: boolean,
  // List of apps
  apps: App[],
  // True if adding an new app
  adding: boolean,
  // App being edited
  appToEdit?: App,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Finish loading
  FinishLoading = 'finish-loading',
  // Show app adder
  ShowAppAdder = 'show-app-adder',
  // Edit an existing app
  ShowAppEditor = 'show-app-editor',
  // Finish editing or adding app
  FinishAddOrEdit = 'finish-add-or-edit',
  // Start deletion process
  StartDelete = 'start-delete',
  // Finish deletion process
  FinishDelete = 'finish-delete',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.FinishLoading,
    // App list
    apps: App[],
  }
  | {
    // Action type
    type: ActionType.ShowAppEditor,
    // App being edited
    app: App,
  }
  | {
    // Action type
    type: ActionType.FinishAddOrEdit,
    // App that was added or edited (or undefined if cancelled)
    app?: App,
  }
  | {
    // Action type
    type: ActionType.FinishDelete,
    // App that was deleted
    app: App,
  }
  | {
    // Action type
    type: (
      | ActionType.ShowAppAdder
      | ActionType.StartDelete
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.FinishLoading: {
      return {
        ...state,
        loading: false,
        apps: action.apps,
      };
    }
    case ActionType.ShowAppAdder: {
      return {
        ...state,
        adding: true,
        appToEdit: undefined,
      };
    }
    case ActionType.ShowAppEditor: {
      return {
        ...state,
        adding: false,
        appToEdit: action.app,
      };
    }
    case ActionType.FinishAddOrEdit: {
      // Handle cancel
      const finishedApp = action.app;
      if (!finishedApp) {
        return {
          ...state,
          adding: false,
          appToEdit: undefined,
        };
      }

      // Create an updated list of apps
      let updatedApps: App[];
      if (state.adding) {
        updatedApps = [...state.apps, finishedApp];
      } else {
        updatedApps = state.apps.map((existingApp) => {
          if (state.appToEdit && state.appToEdit.id === existingApp.id) {
            // This is the app being edited! Replace
            return finishedApp;
          }
          // This is not the app being edited
          return existingApp;
        });
      }

      // Update the state
      return {
        ...state,
        adding: false,
        appToEdit: undefined,
        apps: updatedApps,
      };
    }
    case ActionType.StartDelete: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.FinishDelete: {
      return {
        ...state,
        loading: false,
        apps: state.apps.filter((app) => {
          return (app.id !== action.app.id);
        }),
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AppStoreEditorPanel: React.FC<{}> = () => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    loading: true,
    apps: [],
    adding: false,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    loading,
    apps,
    adding,
    appToEdit,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Delete an app
   * @author Gabe Abrams
   * @param app the app to delete
   */
  const deleteApp = async (app: App) => {
    // Confirm once
    if (!await confirm('Remove app from app store?', 'Are you sure? This operation cannot be undone!')) {
      return;
    }

    // Confirm again
    if (!await confirm('This action is permanent!', 'Are you sure you want to remove this app from the app store? Metadata, information, icons, guides, etc will all be lost. This will not affect installed copies of this app.')) {
      return;
    }

    // Remove the app
    try {
      // Start loader
      dispatch({
        type: ActionType.StartDelete,
      });

      // Perform deletion
      await visitServerEndpoint({
        path: `/api/admin/services/app-store/apps/${app.id}`,
        method: 'DELETE',
      });

      // Finish loader
      dispatch({
        type: ActionType.FinishDelete,
        app,
      });
    } catch (err) {
      return showFatalError(err);
    }
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      (async () => {
        // Load list of apps
        try {
          // Get list of apps
          const response = await visitServerEndpoint({
            path: '/api/services/app-store/apps',
            method: 'GET',
            params: {
              dontFilter: true,
            },
          });

          // Save loaded apps
          dispatch({
            type: ActionType.FinishLoading,
            apps: response.apps,
          });
        } catch (err) {
          return showFatalError(err);
        }
      })();
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;

  /* ------------- Loading ------------ */

  if (loading) {
    body = (
      <LoadingSpinner />
    );
  }

  /* ------------ App List ------------ */

  if (!loading && !adding && !appToEdit) {
    // Create body
    body = (
      <div>
        <TabBox
          title="App Store Apps"
        >
          {/* List of App Categories */}
          {categorizeApps(apps).map(({ category, appsInCategory }) => {
            return (
              <div
                key={category}
              >
                {/* Title */}
                <h4 className="mb-1 text-center">
                  <span className="fw-bold">
                    {category}
                  </span>
                  {' '}
                  Apps:
                </h4>

                {/* List of apps */}
                {
                  appsInCategory.map((app) => {
                    return (
                      <div
                        key={app.id}
                        className="alert alert-secondary p-2 mb-2 d-flex align-items-center justify-content-center mb-1"
                      >
                        {/* Icon */}
                        <div className="d-none d-md-flex pe-2 align-items-center justify-content-center">
                          <IconPreview
                            icon={app.icon}
                            sizeInRems={3}
                          />
                        </div>
                        {/* Name */}
                        <div className="flex-grow-1">
                          <h4 className="m-0">
                            {app.name}
                          </h4>
                        </div>
                        {/* Buttons */}
                        <div className="d-flex align-items-center">
                          <button
                            type="button"
                            id={`AppStoreEditorPanel-remove-app-with-id-${app.id}`}
                            className="btn btn-secondary me-1"
                            aria-label={`remove app: ${app.name}`}
                            onClick={() => {
                              deleteApp(app);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                            />
                            <span className="d-none d-md-inline ms-1">
                              Remove
                            </span>
                          </button>

                          <button
                            type="button"
                            id={`AppStoreEditorPanel-edit-app-with-id-${app.id}`}
                            className="btn btn-primary"
                            aria-label={`edit app: ${app.name}`}
                            onClick={() => {
                              dispatch({
                                type: ActionType.ShowAppEditor,
                                app,
                              });
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCog}
                            />
                            <span className="d-none d-md-inline ms-1">
                              Edit
                            </span>
                          </button>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            );
          })}

          {/* Add App Button */}
          <div className="d-grid">
            <button
              type="button"
              id="AppStoreEditorPanel-add-app"
              className="btn btn-lg btn-primary"
              aria-label="add a new app to the list of available apps"
              onClick={() => {
                dispatch({
                  type: ActionType.ShowAppAdder,
                });
              }}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className="me-2"
              />
              Add App
            </button>
          </div>
        </TabBox>
      </div>
    );
  }

  /* --------- Create/Edit App -------- */

  if (!loading && (adding || appToEdit)) {
    body = (
      <AddOrEditApp
        appToEdit={appToEdit}
        allApps={apps}
        onFinished={(app) => {
          dispatch({
            type: ActionType.FinishAddOrEdit,
            app,
          });
        }}
      />
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div>
      {body}
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AppStoreEditorPanel;
