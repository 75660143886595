/**
 * The list of all the apps in the app store
 * @author Benedikt Arnarsson
 * @author Gabe Abrams
 */
import React, { useReducer } from 'react';

// Import dce-reactkit
import { LogAction, logClientEvent } from 'dce-reactkit';

// Import shared types
import App from '../../../shared/types/from-server/stored/App';
import InstalledAppMap from '../../../shared/types/from-server/InstalledAppMap';
import RequestedAppMap from '../../../shared/types/from-server/RequestedAppMap';
import LogMetadata from '../../../shared/types/from-server/LogMetadata';

// Import shared helpers
import categorizeApps from '../../../shared/helpers/categorizeApps';

// Import other components
import AppBox from './AppBox';
import AppMoreInfoModal from './AppMoreInfoModal';
import HowToFindModal from './HowToFindModal';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // The list of apps passed from parent
  apps: App[],
  // If true, the user is an admin
  isAdmin: boolean,
  // Map from appID to boolean indicating whether app is installed
  installedAppMap: InstalledAppMap,
  // Map from appId to a boolean indicating whether the app was requested
  requestedAppMap: RequestedAppMap,
  // Handler for when the user wants to add an app
  onAddApp: (app: App) => void,
  // Handler for when the user wants to remove an app
  onRemoveApp: (app: App) => void,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // The app to show more info for
  moreInfoApp?: App,
  // The app to show how to find
  howToFindApp?: App,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Show more info for an app
  ShowMoreInfo = 'show-more-info',
  // Hide more info modal
  HideMoreInfo = 'hide-more-info',
  // Show how to find an app
  ShowHowToFind = 'show-how-to-find',
  // Hide how to find modal
  HideHowToFind = 'hide-how-to-find',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.ShowMoreInfo,
    // The app to show more info for
    app: App,
  }
  | {
    // Action type
    type: ActionType.ShowHowToFind,
    // The app to show how to find
    app: App,
  }
  | {
    // Action type
    type: (
      | ActionType.HideMoreInfo
      | ActionType.HideHowToFind
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ShowMoreInfo: {
      return {
        ...state,
        moreInfoApp: action.app,
        howToFindApp: undefined,
      };
    }
    case ActionType.HideMoreInfo: {
      return {
        ...state,
        moreInfoApp: undefined,
        howToFindApp: undefined,
      };
    }
    case ActionType.ShowHowToFind: {
      return {
        ...state,
        moreInfoApp: undefined,
        howToFindApp: action.app,
      };
    }
    case ActionType.HideHowToFind: {
      return {
        ...state,
        howToFindApp: undefined,
        moreInfoApp: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AppList: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    apps,
    isAdmin,
    installedAppMap,
    onAddApp,
    onRemoveApp,
    requestedAppMap,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    moreInfoApp: undefined,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    moreInfoApp,
    howToFindApp,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Modal --------------- */
  /*----------------------------------------*/

  let modal: React.ReactNode;

  /* ------------ More Info ----------- */

  if (moreInfoApp) {
    modal = (
      <AppMoreInfoModal
        app={moreInfoApp}
        isAdmin={isAdmin}
        onClose={() => {
          dispatch({
            type: ActionType.HideMoreInfo,
          });
        }}
        isInstalled={!!installedAppMap[moreInfoApp.id]}
        isRequested={!!requestedAppMap[moreInfoApp.id]}
        onAdd={() => {
          // Hide the modal
          dispatch({
            type: ActionType.HideMoreInfo,
          });

          // Add the app
          onAddApp(moreInfoApp);
        }}
        onRemove={() => {
          // Hide the modal
          dispatch({
            type: ActionType.HideMoreInfo,
          });

          // Remove the app
          onRemoveApp(moreInfoApp);
        }}
        onShowHowToFind={() => {
          dispatch({
            type: ActionType.ShowHowToFind,
            app: moreInfoApp,
          });
        }}
      />
    );
  }

  /* ----------- How to Find ---------- */

  if (howToFindApp) {
    modal = (
      <HowToFindModal
        app={howToFindApp}
        onClose={() => {
          dispatch({
            type: ActionType.HideHowToFind,
          });
        }}
      />
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div className="AppList-container">
      {/* Modal */}
      {modal}

      {/* App Categories */}
      {
        (apps && apps.length > 0)
          ? categorizeApps(apps).map(({ category, appsInCategory }) => {
            return (
              <div
                key={category}
                className="mb-4"
              >
                {/* Title */}
                <h3 className="mb-1 fw-bold text-center">
                  {category}
                </h3>

                {/* Apps */}
                {appsInCategory.map((app) => {
                  return (
                    <AppBox
                      key={app.id}
                      app={app}
                      isAdmin={isAdmin}
                      isInstalled={!!installedAppMap[app.id]}
                      isRequested={!!requestedAppMap[app.id]}
                      onShowMoreInfo={() => {
                        // Log showing more info
                        logClientEvent({
                          context: LogMetadata.Context.AppStore,
                          subcontext: LogMetadata.Context.AppStore.App,
                          action: LogAction.View,
                          metadata: {
                            appId: app.id,
                            appName: app.name,
                          },
                        });

                        // Update state
                        dispatch({
                          type: ActionType.ShowMoreInfo,
                          app,
                        });
                      }}
                      onShowHowToFind={() => {
                        // Log showing how to find
                        logClientEvent({
                          context: LogMetadata.Context.AppStore,
                          subcontext: LogMetadata.Context.AppStore.App,
                          action: LogAction.Peek,
                          metadata: {
                            appId: app.id,
                            appName: app.name,
                          },
                        });

                        // Update state
                        dispatch({
                          type: ActionType.ShowHowToFind,
                          app,
                        });
                      }}
                      onAdd={() => {
                        onAddApp(app);
                      }}
                      onRemove={() => {
                        onRemoveApp(app);
                      }}
                    />
                  );
                })}
              </div>
            );
          })
          : (
            <p>
              No Apps
            </p>
          )
      }
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AppList;
