/**
 * App store
 * @author Gabe Abrams
 */

// Import react
import React, { useReducer, useEffect } from 'react';

// Import dce-reactkit
import {
  LoadingSpinner,
  LogAction,
  logClientEvent,
  showFatalError,
  visitServerEndpoint,
} from 'dce-reactkit';

// Import other components
import AppList from './AppList';
import AddModal from './AddModal';
import SubmitRequestModal from './SubmitRequestModal';
import RemoveAppModal from './RemoveAppModal';

// Import shared types
import App from '../../shared/types/from-server/stored/App';
import InstalledAppMap from '../../shared/types/from-server/InstalledAppMap';
import ServiceTopComponentProps from '../../shared/types/ServiceTopComponentProps';
import RequestedAppMap from '../../shared/types/from-server/RequestedAppMap';
import AddType from '../../shared/types/from-server/stored/AddType';
import LogMetadata from '../../shared/types/from-server/LogMetadata';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

enum AppOperation {
  // User is requesting an app
  Request = 'request',
  // User is adding an app
  Add = 'add',
  // User is removing an app
  Remove = 'remove',
}

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // True if finished loading
  doneLoading: boolean,
  // The list of Apps which the AppList renders
  apps: App[],
  // Map from appId to a boolean indicating whether the app is installed
  installedAppMap: InstalledAppMap,
  // Map from appId to a boolean indicating whether the app was requested
  requestedAppMap: RequestedAppMap,
  // Info on the current app operation
  appOperationInfo?: {
    // Current selected app
    app: App,
    // Type of app operation
    operation: AppOperation,
  },
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Sets doneLoading to true
  FinishLoading = 'finish-loading',
  // Start an operation
  StartAppOperation = 'start-app-operation',
  // Cancel an app operation
  CancelAppOperation = 'cancel-app-operation',
  // Finish an app operation
  FinishAppOperation = 'finish-app-operation',
  // Switch from request to add
  BypassRequest = 'bypass-request',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.FinishLoading,
    // List of apps that can be added
    apps: App[],
    // Map of installed apps
    installedAppMap: InstalledAppMap,
    // Map of requested apps
    requestedAppMap: RequestedAppMap,
  }
  | {
    // Action type
    type: ActionType.StartAppOperation,
    // The app associated with the operation
    app: App,
    // Type of operation
    operation: AppOperation,
  }
  | {
    // Action type (no payload)
    type: (
      | ActionType.CancelAppOperation
      | ActionType.FinishAppOperation
      | ActionType.BypassRequest
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Benedikt Arnarsson
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.FinishLoading: {
      return {
        ...state,
        doneLoading: true,
        apps: action.apps,
        installedAppMap: action.installedAppMap,
        requestedAppMap: action.requestedAppMap,
      };
    }
    case ActionType.StartAppOperation: {
      return {
        ...state,
        appOperationInfo: {
          app: action.app,
          operation: action.operation,
        },
      };
    }
    case ActionType.CancelAppOperation: {
      return {
        ...state,
        appOperationInfo: undefined,
      };
    }
    case ActionType.FinishAppOperation: {
      // Finish if not operating on an app
      if (!state.appOperationInfo) {
        return state;
      }

      // Request
      if (state.appOperationInfo.operation === AppOperation.Request) {
        // Update the requestedAppMap
        const { requestedAppMap } = state;
        requestedAppMap[state.appOperationInfo.app.id] = true;

        // Return new state
        return {
          ...state,
          requestedAppMap,
          appOperationInfo: undefined,
        };
      }

      // Add or Remove:
      // Update the installedAppMap
      const { installedAppMap } = state;
      installedAppMap[state.appOperationInfo.app.id] = (
        state.appOperationInfo.operation === AppOperation.Add
      );

      // Return new state
      return {
        ...state,
        installedAppMap,
        appOperationInfo: undefined,
      };
    }
    case ActionType.BypassRequest: {
      // Finish if not operating on an app
      if (!state.appOperationInfo) {
        return state;
      }

      // Switch to add
      return {
        ...state,
        appOperationInfo: {
          ...state.appOperationInfo,
          operation: AppOperation.Add,
        },
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AppStore: React.FC<ServiceTopComponentProps> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* --------------- Props -------------*/

  // destructure all props
  const {
    launchInfo,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    doneLoading: false,
    apps: [],
    installedAppMap: {},
    requestedAppMap: {},
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    doneLoading,
    apps,
    installedAppMap,
    requestedAppMap,
    appOperationInfo,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount the AppList - loads in the list of apps from the database
   * @author Benedikt Arnarsson
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      (async () => {
        try {
          // Getting the apps and installedAppMap from the database
          const response = await visitServerEndpoint({
            path: '/api/services/app-store/apps',
            method: 'GET',
          });

          // Log app list loading
          logClientEvent({
            context: LogMetadata.Context.AppStore,
            subcontext: LogMetadata.Context.AppStore.List,
            action: LogAction.View,
          });

          // Hide the LoadingIcon and reveal the AppList
          dispatch({
            type: ActionType.FinishLoading,
            apps: response.apps,
            installedAppMap: response.installedAppMap,
            requestedAppMap: response.requestedAppMap,
          });
        } catch (err) {
          return showFatalError(err);
        }
      })();
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Modal --------------- */
  /*----------------------------------------*/

  // Modal that may be defined
  let modal: React.ReactNode;

  /* ---------- App Operation --------- */

  if (appOperationInfo) {
    /* --------------- Add -------------- */

    if (appOperationInfo.operation === AppOperation.Add) {
      modal = (
        <AddModal
          key={`add-popup-${appOperationInfo.app.id}`}
          app={appOperationInfo.app}
          courseId={launchInfo.courseId}
          onFinished={() => {
            dispatch({
              type: ActionType.FinishAppOperation,
            });
          }}
          onCancelled={() => {
            dispatch({
              type: ActionType.CancelAppOperation,
            });
          }}
        />
      );
    }

    /* ------------- Request ------------ */

    if (appOperationInfo.operation === AppOperation.Request) {
      modal = (
        <SubmitRequestModal
          key={`request-popup-${appOperationInfo.app.id}`}
          app={appOperationInfo.app}
          isAdmin={launchInfo.isAdmin}
          onFinished={() => {
            dispatch({
              type: ActionType.FinishAppOperation,
            });
          }}
          onCancelled={() => {
            dispatch({
              type: ActionType.CancelAppOperation,
            });
          }}
          onBypass={() => {
            dispatch({
              type: ActionType.BypassRequest,
            });
          }}
        />
      );
    }

    /* ------------- Remove ------------- */

    if (appOperationInfo.operation === AppOperation.Remove) {
      modal = (
        <RemoveAppModal
          key={`remove-popup-${appOperationInfo.app.id}`}
          app={appOperationInfo.app}
          courseId={launchInfo.courseId}
          onFinished={() => {
            dispatch({
              type: ActionType.FinishAppOperation,
            });
          }}
          onCancelled={() => {
            dispatch({
              type: ActionType.CancelAppOperation,
            });
          }}
        />
      );
    }
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div>
      {modal}
      {
        doneLoading
          ? (
            <AppList
              apps={apps}
              isAdmin={launchInfo.isAdmin}
              installedAppMap={installedAppMap}
              requestedAppMap={requestedAppMap}
              onAddApp={async (app) => {
                // Check if this app requires a request
                const requiresRequest = (
                  app.requestOnly
                  || app.addType === AddType.Manual
                );
                if (requiresRequest) {
                  // Start request
                  dispatch({
                    type: ActionType.StartAppOperation,
                    app,
                    operation: AppOperation.Request,
                  });
                } else {
                  // Start add
                  dispatch({
                    type: ActionType.StartAppOperation,
                    app,
                    operation: AppOperation.Add,
                  });
                }
              }}
              onRemoveApp={(app) => {
                dispatch({
                  type: ActionType.StartAppOperation,
                  app,
                  operation: AppOperation.Remove,
                });
              }}
            />
          )
          : (
            <LoadingSpinner />
          )
      }
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

export default AppStore;
