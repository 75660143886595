/**
 * Modal that guides user through requesting an app install
 * @author Yuen Ler Chow
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer, useEffect } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

// Import Markdown
import Markdown from 'markdown-to-jsx';

// Import dce-reactkit
import {
  visitServerEndpoint,
  LoadingSpinner,
  Modal,
  ModalType,
  ModalButtonType,
  showFatalError,
  startMinWait,
  PopSuccessMark,
  Variant,
  waitMs,
  alert,
  LogAction,
  logClientEvent,
} from 'dce-reactkit';

// Import shared types
import App from '../../shared/types/from-server/stored/App';
import AddType from '../../shared/types/from-server/stored/AddType';
import LogMetadata from '../../shared/types/from-server/LogMetadata';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // The app being requested
  app: App,
  // True if the user is an admin
  isAdmin: boolean,
  // Handler to call when user requests the app
  onFinished: () => void,
  // Handler to call when user cancels the process
  onCancelled: () => void,
  // Handler to call when admin wants to bypass this step
  onBypass: () => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

// how many milliseconds to wait before allowing user to continue past notice
const MIN_NOTICE_READ_TIME_MS = 2000;

// Min amount of time to wait for request to be sent
const MIN_REQUEST_SPINNER_TIME_MS = 500;

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Notice before submitting request
  NoticeBefore = 'NoticeBefore',
  // Confirm that the user wants to submit the request
  Confirm = 'Confirm',
  // Working on adding the request
  Working = 'Working',
  // Notice after submitting request
  NoticeAfter = 'NoticeAfter',
  // Finished page
  Finished = 'Finished',
}

/* -------- State Definition -------- */

type State = {
  // Current view
  view: View,
  // If true, user is waiting and reading the notice
  userReadingNotice?: boolean,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Show confirm request modal
  ShowConfirm = 'ShowConfirm',
  // Hide working modal
  ShowWorking = 'ShowWorking',
  // Show notice after request
  ShowNoticeAfter = 'ShowNoticeAfter',
  // show finished modal
  ShowFinished = 'ShowFinished',
  // Indicate that the user is done reading the notice
  UserDoneReadingNotice = 'UserDoneReadingNotice',
}

// Action definitions
type Action = (
  | {
    type: (
      | ActionType.ShowConfirm
      | ActionType.ShowWorking
      | ActionType.ShowNoticeAfter
      | ActionType.ShowFinished
      | ActionType.UserDoneReadingNotice
    )
  }
);

/**
 * Reducer that executes actions
 * @author Yuen Ler Chow
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ShowConfirm: {
      return {
        view: View.Confirm,
        userReadingNotice: false,
      };
    }
    case ActionType.ShowWorking: {
      return {
        view: View.Working,
        userReadingNotice: false,
      };
    }
    case ActionType.ShowNoticeAfter: {
      return {
        view: View.NoticeAfter,
        userReadingNotice: true,
      };
    }
    case ActionType.ShowFinished: {
      return {
        view: View.Finished,
        userReadingNotice: false,
      };
    }
    case ActionType.UserDoneReadingNotice: {
      return {
        ...state,
        userReadingNotice: false,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const SubmitRequestModal: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    app,
    onFinished,
    onCancelled,
    isAdmin,
    onBypass,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const showNoticeBefore = !!app.messageBeforeAdd;
  const initialState: State = {
    view: (showNoticeBefore ? View.NoticeBefore : View.Confirm),
    userReadingNotice: showNoticeBefore,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
    userReadingNotice,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Change modal to working, send request, then wait 3 seconds and then
   *   change to finished request (or notice after if there is one)
   * @author Yuen Ler Chow
   * @author Gabe Abrams
   */
  const onConfirm = async () => {
    // change View to Working
    dispatch({
      type: ActionType.ShowWorking,
    });

    // Start min wait
    const endMinWait = startMinWait(MIN_REQUEST_SPINNER_TIME_MS);

    // send install request to server
    try {
      await visitServerEndpoint({
        path: `/api/ttm/services/app-store/install-requests/apps/${app.id}`,
        method: 'POST',
      });
    } catch (err) {
      return showFatalError(err);
    }

    // Log request
    logClientEvent({
      context: LogMetadata.Context.AppStore,
      subcontext: LogMetadata.Context.AppStore.App,
      action: LogAction.Add,
      metadata: {
        appId: app.id,
        appName: app.name,
        isRequest: true,
      },
    });

    // Finish min wait
    await endMinWait();

    // Update state
    if (app.messageAfterAdd) {
      // Show notice after
      dispatch({ type: ActionType.ShowNoticeAfter });

      // Wait for user to read notice
      await waitMs(MIN_NOTICE_READ_TIME_MS);

      // Indicate that the user is done reading
      dispatch({
        type: ActionType.UserDoneReadingNotice,
      });
    } else {
      dispatch({ type: ActionType.ShowFinished });
    }
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount: wait then indicate that the user is done reading
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      (async () => {
        if (showNoticeBefore) {
          // Wait for the user to read the notice
          await waitMs(MIN_NOTICE_READ_TIME_MS);

          // Indicate that the user is done reading
          dispatch({
            type: ActionType.UserDoneReadingNotice,
          });
        }
      })();
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  /* ------- Notice Before/After ------ */

  if (
    view === View.NoticeBefore
    || view === View.NoticeAfter
  ) {
    // Create body
    return (
      <Modal
        key={view === View.NoticeBefore ? 'notice-before' : 'notice-after'}
        title={(
          <>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="me-1"
            />
            Important Notice
          </>
        )}
        type={userReadingNotice ? ModalType.NoButtons : ModalType.Okay}
        okayLabel="I Understand"
        okayVariant={Variant.Warning}
        dontAllowBackdropExit
        dontShowXButton={(
          userReadingNotice
          || view === View.NoticeAfter
        )}
        onClose={
          userReadingNotice
            ? undefined
            : (buttonType) => {
              if (buttonType === ModalButtonType.Okay) {
                // Agreed
                dispatch({
                  type: (
                    view === View.NoticeBefore
                      ? ActionType.ShowConfirm
                      : ActionType.ShowFinished
                  ),
                });
              } else {
                // Closed
                if (view === View.NoticeAfter) {
                  // User must indicate that they understand
                  return alert(
                    'Indicate that you understand',
                    'You must indicate that you understand the notice before continuing.',
                  );
                }

                // User cancelled
                onCancelled();
              }
            }
        }
      >
        <div className="alert alert-warning text-dark m-0">
          <Markdown>
            {
              view === View.NoticeBefore
                ? app.messageBeforeAdd ?? ''
                : app.messageAfterAdd ?? ''
            }
          </Markdown>
        </div>
      </Modal>
    );
  }

  /* ------------- Confirm ------------ */

  if (view === View.Confirm) {
    const canBeBypassed = (app.addType !== AddType.Manual);
    if (isAdmin && canBeBypassed) {
      // Allow bypass
      return (
        <Modal
          title="Bypass Request Step?"
          type={ModalType.YesNoCancel}
          yesLabel="I Have Permission, Bypass"
          yesVariant={Variant.Danger}
          noLabel="Submit Request"
          onClose={(buttonType: ModalButtonType) => {
            if (buttonType === ModalButtonType.Yes) {
              // Bypass
              onBypass();
            } else if (buttonType === ModalButtonType.No) {
              // Continue with request
              onConfirm();
            } else {
              // Cancel
              onCancelled();
            }
          }}
        >
          <div className="text-center">
            <div className="mb-1">
              This app is complex and
              {' '}
              <strong>
                must be requested
              </strong>
              .
              As an admin, you can bypass this step.
            </div>
            <div>
              <div className="alert alert-warning d-inline-block m-0 pt-1 pb-1">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="me-2"
                />
                <strong>
                  Only bypass
                </strong>
                {' '}
                if the DCE Instructional Technology Group gave you
                permission to do so!
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    // Not an admin. No bypass
    return (
      <Modal
        key="confirmation"
        title="Request this app?"
        type={ModalType.ConfirmCancel}
        confirmLabel="Request App"
        onClose={(buttonType: ModalButtonType) => {
          if (buttonType === ModalButtonType.Confirm) {
            onConfirm();
          } else {
            onCancelled();
          }
        }}
      >
        <div id="SubmitRequestModal-confirm-text">
          This app
          {' '}
          <strong>
            must be installed by a specialist
          </strong>
          .
        </div>
        <div>
          We will contact you when the installation is complete.
        </div>
      </Modal>
    );
  }

  /* ------------- Working ------------ */

  if (view === View.Working) {
    // Create body
    return (
      <Modal
        key="working"
        title="Sending request..."
        type={ModalType.NoButtons}
      >
        <div id="SubmitRequestModal-loading-spinner" className="text-center">
          <LoadingSpinner />
        </div>
      </Modal>
    );
  }

  /* ------------ Finished ------------ */

  // Create body
  return (
    <Modal
      key="finished"
      title="Request submitted"
      type={ModalType.Okay}
      onClose={() => {
        onFinished();
      }}
    >
      <div className="d-flex align-items-center justify-content-center">
        <div className="me-2">
          <PopSuccessMark
            sizeRem={4}
          />
        </div>
        <div>
          <div>
            After we complete your request,
          </div>
          <div className="fw-bold">
            we&apos;ll send you an email.
          </div>
        </div>
      </div>
    </Modal>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default SubmitRequestModal;
