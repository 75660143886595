/**
 * Manager for install requests
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer, useEffect } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faHourglass,
  faInfoCircle,
  faMagnifyingGlass,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  LoadingSpinner,
  showFatalError,
  TabBox,
  visitServerEndpoint,
  CheckboxButton,
} from 'dce-reactkit';

// Import other components
import InstallRequestItem from './InstallRequestItem';

// Import shared types
import InstallRequest from '../../shared/types/from-server/stored/InstallRequest';
import InstallRequestStatus from '../../shared/types/from-server/stored/InstallRequestStatus';
import App from '../../shared/types/from-server/stored/App';
import ManualInstallModal from './ManualInstallModal';
import AddType from '../../shared/types/from-server/stored/AddType';
import ContactRequestorModal from './ContactRequestorModal';

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = (
  {
    // True if loading
    loading: boolean,
    // True if ignored requests are visible
    ignoredVisible: boolean,
    // True if finished requests are visible
    finishedVisible: boolean,
    // Search query
    searchQuery: string,
    // List of install requests
    installRequests: InstallRequest[],
    // List of apps
    apps: App[],
    // Manual install info
    manualInstallInfo?: {
      // The request being handled
      request: InstallRequest,
      // App associated with the request
      app: App,
    },
    // Request to contact
    requestToContactInfo?: {
      // The request that was just finished
      request: InstallRequest,
      // App associated with the request
      app: App,
    },
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Show the loading screen
  StartLoading = 'start-loading',
  // Update the list of install requests
  UpdateInstallRequestList = 'update-install-request-list',
  // Toggle whether ignored requests are visible
  ToggleIgnoredRequestVisibility = 'toggle-ignored-request-visibility',
  // Toggle whether finished requests are visible
  ToggleFinishedRequestVisibility = 'toggle-finished-request-visibility',
  // Update the search query text
  UpdateSearchQuery = 'update-search-query',
  // Update a request in the map and stop the loading process
  UpdateRequest = 'update-request',
  // Start the process of manually installing an app
  StartManuallyInstallingApp = 'start-manually-installing-app',
  // Finish the process of manually installing an app (cancel or completed)
  FinishManuallyInstallingApp = 'finish-manually-installing-app',
  // Show contact modal (doubles as confirmation for finished requests)
  ShowContactModal = 'show-contact-modal',
  // Hide a contact modal
  HideContactModal = 'hide-contact-modal',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.UpdateInstallRequestList,
    // Current list of install requests
    installRequests: InstallRequest[],
    // Current list of apps
    apps: App[],
  }
  | {
    // Action type
    type: ActionType.UpdateSearchQuery,
    // New search query text
    searchQuery: string,
  }
  | {
    // Action type
    type: ActionType.UpdateRequest,
    // Updated copy of the request
    request: InstallRequest,
  }
  | {
    // Action type
    type: ActionType.StartManuallyInstallingApp,
    // The app to install
    app: App,
    // The associated install request
    request: InstallRequest,
  }
  | {
    // Action type
    type: ActionType.ShowContactModal,
    // The install request to contact
    request: InstallRequest,
    // The app to contact the requestor over
    app: App,
  }
  | {
    // Action type
    type: (
      | ActionType.StartLoading
      | ActionType.ToggleIgnoredRequestVisibility
      | ActionType.ToggleFinishedRequestVisibility
      | ActionType.FinishManuallyInstallingApp
      | ActionType.HideContactModal
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.StartLoading: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.UpdateInstallRequestList: {
      return {
        ...state,
        loading: false,
        installRequests: action.installRequests,
        apps: action.apps,
      };
    }
    case ActionType.ToggleIgnoredRequestVisibility: {
      return {
        ...state,
        ignoredVisible: !state.ignoredVisible,
      };
    }
    case ActionType.ToggleFinishedRequestVisibility: {
      return {
        ...state,
        finishedVisible: !state.finishedVisible,
      };
    }
    case ActionType.UpdateSearchQuery: {
      return {
        ...state,
        searchQuery: action.searchQuery,
      };
    }
    case ActionType.UpdateRequest: {
      return {
        ...state,
        installRequests: state.installRequests.map((candidateRequest) => {
          if (candidateRequest.id === action.request.id) {
            return action.request;
          }

          return candidateRequest;
        }),
        manualInstallInfo: undefined,
        loading: false,
      };
    }
    case ActionType.StartManuallyInstallingApp: {
      return {
        ...state,
        manualInstallInfo: {
          request: action.request,
          app: action.app,
        },
      };
    }
    case ActionType.FinishManuallyInstallingApp: {
      return {
        ...state,
        manualInstallInfo: undefined,
      };
    }
    case ActionType.ShowContactModal: {
      return {
        ...state,
        requestToContactInfo: {
          request: action.request,
          app: action.app,
        },
      };
    }
    case ActionType.HideContactModal: {
      return {
        ...state,
        requestToContactInfo: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const InstallRequestManagerPanel: React.FC<{}> = () => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    loading: true,
    ignoredVisible: false,
    finishedVisible: false,
    searchQuery: '',
    installRequests: [],
    apps: [],
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    loading,
    ignoredVisible,
    finishedVisible,
    searchQuery,
    installRequests,
    apps,
    manualInstallInfo,
    requestToContactInfo,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Fetch new list of install requests and apps from server
   * @author Gabe Abrams
   */
  const reload = async () => {
    // Show loading indicator
    dispatch({ type: ActionType.StartLoading });

    // Get new list of requests
    try {
      const [
        newInstallRequests,
        appListResponse,
      ] = await Promise.all([
        visitServerEndpoint({
          path: '/api/admin/services/app-store/install-requests',
          method: 'GET',
        }),
        visitServerEndpoint({
          path: '/api/services/app-store/apps',
          method: 'GET',
        }),
      ]);

      // Update state
      dispatch({
        type: ActionType.UpdateInstallRequestList,
        installRequests: newInstallRequests,
        apps: appListResponse.apps,
      });
    } catch (err) {
      return showFatalError(err);
    }
  };

  /**
   * Mark a request as ignored
   * @author Gabe Abrams
   * @param request the request to ignore
   * @param app the app associated with the request
   */
  const ignoreRequest = async (request: InstallRequest, app: App) => {
    try {
      // Start the loader
      dispatch({
        type: ActionType.StartLoading,
      });

      // Ignore the request
      const updatedRequest = await visitServerEndpoint({
        path: `/api/admin/services/app-store/install-requests/${request.id}/ignore`,
        method: 'PUT',
      });

      // Successfully ignored. Update state
      dispatch({
        type: ActionType.UpdateRequest,
        request: updatedRequest,
      });

      // Show the confirmation panel
      dispatch({
        type: ActionType.ShowContactModal,
        request: updatedRequest,
        app,
      });
    } catch (err) {
      return showFatalError(err);
    }
  };

  /**
   * Mark an install request as completed
   * @author Gabe Abrams
   * @param request the request to mark as completed
   * @param app the app being installed
   */
  const markRequestAsCompleted = async (request: InstallRequest, app: App) => {
    // Show the loader
    dispatch({
      type: ActionType.StartLoading,
    });

    try {
      // Update the request
      const updatedRequest = await visitServerEndpoint({
        path: `/api/admin/services/app-store/install-requests/${request.id}/finish`,
        method: 'PUT',
      });

      // Successfully updated. Update state
      dispatch({
        type: ActionType.UpdateRequest,
        request: updatedRequest,
      });

      // Show the confirmation panel
      dispatch({
        type: ActionType.ShowContactModal,
        request: updatedRequest,
        app,
      });
    } catch (err) {
      return showFatalError(err);
    }
  };

  /**
   * Perform an install of an app
   * @author Gabe Abrams
   * @param request the request to complete
   * @param app the app to install
   */
  const installRequest = async (request: InstallRequest, app: App) => {
    // Show the loader
    dispatch({
      type: ActionType.StartLoading,
    });

    // Perform the installation
    try {
      await visitServerEndpoint({
        path: `/api/services/app-store/courses/${request.courseId}/apps/${app.id}/install`,
        method: 'POST',
      });
    } catch (err) {
      return showFatalError(err);
    }

    // Mark the request as completed
    await markRequestAsCompleted(request, app);
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      reload();
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Modal --------------- */
  /*----------------------------------------*/

  // Modal that will be filled with any current modal
  let modal: React.ReactNode;

  /* ------- Manual Install Info ------ */

  if (manualInstallInfo) {
    modal = (
      <ManualInstallModal
        request={manualInstallInfo.request}
        app={manualInstallInfo.app}
        onCancel={() => {
          dispatch({
            type: ActionType.FinishManuallyInstallingApp,
          });
        }}
        onFinished={() => {
          markRequestAsCompleted(
            manualInstallInfo.request,
            manualInstallInfo.app,
          );
        }}
      />
    );
  }

  /* ---------- Contact Modal --------- */

  if (requestToContactInfo) {
    modal = (
      <ContactRequestorModal
        request={requestToContactInfo.request}
        app={requestToContactInfo.app}
        onClose={() => {
          dispatch({
            type: ActionType.HideContactModal,
          });
        }}
      />
    );
  }

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;

  /* ------------- Loading ------------ */

  if (loading) {
    // Create body
    body = (
      <LoadingSpinner />
    );
  }

  /* ------------- Manager ------------ */

  if (!loading) {
    // Clean up the query
    const cleanedQuery = searchQuery.toLowerCase().trim();

    // Filter and create request elems
    const pendingElems: React.ReactNode[] = [];
    const ignoredElems: React.ReactNode[] = [];
    const finishedElems: React.ReactNode[] = [];
    installRequests.forEach((request) => {
      // Find the matching app
      const app = apps.find((candidateApp) => {
        return (request.appId === candidateApp.id);
      });
      if (!app) {
        return;
      }

      // Filter by search query
      if (
        !request.courseName.toLowerCase().includes(cleanedQuery)
        && !request.requestorEmail.toLowerCase().includes(cleanedQuery)
        && !`${request.requestorFirstName} ${request.requestorLastName}`.toLowerCase().includes(cleanedQuery)
        && !app.name.toLowerCase().includes(cleanedQuery)
      ) {
        // No match
        return;
      }

      // Create the element
      const elem = (
        <InstallRequestItem
          key={request.id}
          request={request}
          app={app}
          onIgnore={() => {
            ignoreRequest(request, app);
          }}
          onInstall={() => {
            if (app.addType === AddType.Manual) {
              // Install manually
              dispatch({
                type: ActionType.StartManuallyInstallingApp,
                request,
                app,
              });
            } else {
              // Install automatically
              installRequest(request, app);
            }
          }}
          onContact={() => {
            dispatch({
              type: ActionType.ShowContactModal,
              request,
              app,
            });
          }}
        />
      );

      // Add to appropriate list
      if (request.status === InstallRequestStatus.Pending) {
        pendingElems.push(elem);
      } else if (request.status === InstallRequestStatus.Ignored) {
        ignoredElems.push(elem);
      } else {
        finishedElems.push(elem);
      }
    });

    // Create body
    body = (
      <div>
        <div>
          {/* Filters */}
          <TabBox title="Filters">
            <div className="d-flex align-items-center">
              {/* Ignored Request Toggle */}
              <CheckboxButton
                text="Show Ignored"
                ariaLabel="show ignored requests"
                onChanged={() => {
                  dispatch({ type: ActionType.ToggleIgnoredRequestVisibility });
                }}
                checked={ignoredVisible}
              />

              {/* Finished Request Toggle */}
              <CheckboxButton
                text="Show Finished"
                ariaLabel="show finished requests"
                onChanged={() => {
                  dispatch({ type: ActionType.ToggleFinishedRequestVisibility });
                }}
                checked={finishedVisible}
              />

              {/* Search Input */}
              <div className="flex-grow-1 text-end">
                <div className="input-group flex-nowrap">
                  <span className="input-group-text">
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                    />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      dispatch({
                        type: ActionType.UpdateSearchQuery,
                        searchQuery: e.target.value,
                      });
                    }}
                    placeholder="search app name, course code, requestor name or email"
                    value={searchQuery}
                  />
                </div>
              </div>
            </div>
          </TabBox>
        </div>

        {/* Pending */}
        <TabBox
          title={(
            <span>
              <FontAwesomeIcon
                icon={faHourglass}
                className="text-secondary me-1"
                style={{
                  fontSize: '0.9em',
                  transform: 'translateY(-0.02em)',
                }}
              />
              Pending Requests
            </span>
          )}
          noBottomPadding
        >
          {/* Pending Requests */}
          {pendingElems}

          {/* Nothing Here Notice */}
          {pendingElems.length === 0 && (
            <div className="text-center">
              <div className="alert alert-secondary p-2 d-inline-block text-center mb-2">
                <div className="fw-bold">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="me-2"
                  />
                  Nothing Here
                </div>
                <div>
                  Either there are no pending requests
                  or your filters are too strict.
                </div>
              </div>
            </div>
          )}
        </TabBox>

        {/* Ignored */}
        {ignoredVisible && (
          <TabBox
            title={(
              <span>
                <FontAwesomeIcon
                  icon={faTimes}
                  className="text-secondary me-1"
                  style={{
                    fontSize: '0.9em',
                    transform: 'translateY(-0.02em)',
                  }}
                />
                Ignored Requests
              </span>
            )}
            noBottomPadding
          >
            {/* Ignore Elements */}
            {ignoredElems}

            {/* Nothing Here */}
            {ignoredElems.length === 0 && (
              <div className="text-center">
                <div className="alert alert-secondary p-2 d-inline-block text-center mb-2">
                  <div className="fw-bold">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="me-2"
                    />
                    Nothing Here
                  </div>
                  <div>
                    Either there are no ignored requests
                    or your filters are too strict.
                  </div>
                </div>
              </div>
            )}
          </TabBox>
        )}

        {/* Finished */}
        {finishedVisible && (
          <TabBox
            title={(
              <span>
                <FontAwesomeIcon
                  icon={faCheck}
                  className="text-secondary me-1"
                  style={{
                    fontSize: '0.9em',
                    transform: 'translateY(-0.02em)',
                  }}
                />
                Finished Requests
              </span>
            )}
            noBottomPadding
          >
            {/* Finished Requests */}
            {finishedElems}

            {/* Nothing Here */}
            {finishedElems.length === 0 && (
              <div className="text-center">
                <div className="alert alert-secondary p-2 d-inline-block text-center mb-2">
                  <div className="fw-bold">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="me-2"
                    />
                    Nothing Here
                  </div>
                  <div>
                    Either there are no finished requests
                    or your filters are too strict.
                  </div>
                </div>
              </div>
            )}
          </TabBox>
        )}
      </div>
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div>
      {/* Header */}
      <h2 className="text-center">
        Install Requests
      </h2>

      {/* Add Modal */}
      {modal}

      {/* Add Body */}
      {body}
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default InstallRequestManagerPanel;
