/**
 * Type out a number of chars over a given number of seconds
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import style
import './Typewriter.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Text to write
  text: string,
  // Number of seconds to take
  secs: number,
  // Number of seconds to delay the animation
  delaySecs?: number,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const Typewriter: React.FC<Props> = (props) => {
  const {
    text,
    secs,
    delaySecs = 0,
  } = props;

  // Split text into chars
  const chars = text.split('');

  return (
    <span>
      {
        chars
          .map((char, i) => {
            // Generate a key
            const key = `${char}-${i}`;

            // Add a whitespace char
            if (char.trim().length === 0) {
              // Whitespace
              return (
                <span key={key}>
                  {' '}
                </span>
              );
            }

            return (
              <span
                key={key}
                className="Typewriter-letter"
                style={{
                  animationDelay: `${(i * (secs / chars.length)) + delaySecs}s`,
                }}
              >
                {char}
              </span>
            );
          })
      }
    </span>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default Typewriter;
