/**
 * Allowed statuses for install requests
 * @author Gabe Abrams
 */
enum InstallRequestStatus {
  Pending = 'pending',
  Ignored = 'ignored',
  Finished = 'finished',
}

export default InstallRequestStatus;
