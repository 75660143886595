import { visitServerEndpoint } from 'dce-reactkit';

/**
 * Validates the web name of a training category.
 * @author Yuen Ler Chow
 * @param webName the web name of the training category
 * @returns true if the web name is valid, false otherwise
 */
const isValidWebName = async (webName: string): Promise<Boolean> => {
  // list events for the web name
  try {
    await visitServerEndpoint({
      method: 'GET',
      path: `/api/services/trainings-list/events/${webName}`,
    });
    return true;
  } catch (e) {
    return false;
  }
};

export default isValidWebName;
