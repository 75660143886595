/**
 * Modal for the process of removing an app from
 * a Canvas course
 * @author Benedikt Arnarsson
 */

// Import React
import React, { useReducer } from 'react';

// Import dce-reactkit
import {
  LoadingSpinner,
  Modal,
  ModalType,
  visitServerEndpoint,
  showFatalError,
  ModalButtonType,
  startMinWait,
  LogAction,
  logClientEvent,
} from 'dce-reactkit';

// Import shared types
import App from '../../shared/types/from-server/stored/App';
import LogMetadata from '../../shared/types/from-server/LogMetadata';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

type Props = {
  // App to be removed
  app: App,
  // Handler for when cancelling removal
  onCancelled: () => void,
  // Handler for when removal is finished
  onFinished: () => void,
  // Id of the course to remove the app from
  courseId: number,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

// Time spent on the LoadingSpinner view
const REMOVAL_LOADING_SPINNER_MS = 500;

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Modal with warning message and confirm button
  ConfirmRemove = 'confirm-remove',
  // Modal with LoadingSpinner while removing app
  Working = 'working',
  // Final 'success' modal
  Finished = 'finished',
}

/* -------- State Definition -------- */

type State = {
  // Keeping track of which modal we are displaying
  view: View,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Go-to next view
  ChangeView = 'change-view',
}

// Action definitions
type Action = {
  // Go-to next view
  type: ActionType.ChangeView,
  // View to transition to
  view: View,
};

/**
 * Reducer that executes actions
 * @author Benedikt Arnarsson
 * @param state current view
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ChangeView: {
      return {
        view: action.view,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const RemoveAppModal: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    app,
    onFinished,
    onCancelled,
    courseId,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    view: View.ConfirmRemove,
  };

  // Initialize reducer
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Remove app from Canvas
   * @author Benedikt Arnarsson
   */
  const executeRemoval = async () => {
    // Update the state to the LoadingSpinner a.k.a. Working View
    dispatch({
      type: ActionType.ChangeView,
      view: View.Working,
    });

    // Start min wait
    const endMinWait = startMinWait(REMOVAL_LOADING_SPINNER_MS);

    try {
      await visitServerEndpoint({
        path: `/api/services/app-store/courses/${courseId}/apps/${app.id}/remove`,
        method: 'DELETE',
      });
    } catch (err) {
      return showFatalError(err);
    }

    // Log removal
    logClientEvent({
      context: LogMetadata.Context.AppStore,
      subcontext: LogMetadata.Context.AppStore.App,
      action: LogAction.Remove,
      metadata: {
        appId: app.id,
        appName: app.name,
      },
    });

    // Finish min wait
    await endMinWait();

    // Close LoadingSpinner
    dispatch({
      type: ActionType.ChangeView,
      view: View.Finished,
    });
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Modal that will be defined by the current view
  let modal: React.ReactNode;

  /* -------- Confirm Remove View -------- */

  if (view === View.ConfirmRemove) {
    modal = (
      <Modal
        key="confirm-cancel"
        type={ModalType.ConfirmCancel}
        title={`Remove ${app.name}?`}
        onClose={(type: ModalButtonType) => {
          if (type === ModalButtonType.Confirm) {
            executeRemoval();
          } else {
            onCancelled();
          }
        }}
        confirmLabel="Remove"
      >
        <div>
          Are you sure you want to remove this app?
        </div>
      </Modal>
    );
  }

  /* -------- Working View -------- */

  if (view === View.Working) {
    modal = (
      <Modal
        key="working"
        title="Removing..."
        type={ModalType.NoButtons}
      >
        <LoadingSpinner />
      </Modal>
    );
  }

  /* -------- Finished View -------- */

  if (view === View.Finished) {
    modal = (
      <Modal
        key="finished"
        title="App Removed"
        type={ModalType.Okay}
        onClose={onFinished}
      >
        {`${app.name} was removed from the course.`}
      </Modal>
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <span>
      {modal}
    </span>
  );
};

export default RemoveAppModal;
