/**
 * Log contexts, tags, and other metadata
 * @author Gabe Abrams
 */
const LogMetadata = {
  // Contexts
  Context: {
    General: 'General',
    Intro: 'Intro',
    AppStore: {
      _: 'AppStore',
      List: 'List',
      App: 'App',
    },
  },
  // Tags
  Tag: {},
  // Targets
  Target: {},
};

export default LogMetadata;
