/**
 * Hello icon. Parent container's width should be 2.9 times the size
 *   of the height.
 * @author Gabe Abrams
 */

// Import style
import './HelloIcon.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

type Props = {
  // True if animating in
  animate?: boolean,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const HelloIcon: React.FC<Props> = (props) => {
  const { animate } = props;

  return (
    <div
      className={`HelloIcon-container${animate ? ' HelloIcon-animate' : ''}`}
      role="img"
      aria-label="Harvard HELLO Icon"
    >
      <span className="sr-only">
        Hello
      </span>
      <div className="HelloIcon-H">
        <div className="HelloIcon-H-line-1 HelloIcon-wipe-down" />
        <div className="HelloIcon-H-line-2 HelloIcon-wipe-right HelloIcon-delay-3" />
        <div className="HelloIcon-H-line-3 HelloIcon-wipe-down HelloIcon-delay-2" />
      </div>
      <div className="HelloIcon-letter-spacer" />
      <div className="HelloIcon-E">
        <div className="HelloIcon-E-color-circle">
          <div className="HelloIcon-E-circle-blank-center HelloIcon-shrink-in HelloIcon-delay-4">
            <div className="HelloIcon-E-hline HelloIcon-wipe-right HelloIcon-delay-6" />
          </div>
          <div className="HelloIcon-E-circle-cutout" />
        </div>
      </div>
      <div className="HelloIcon-letter-spacer" />
      <div className="HelloIcon-L1">
        <div className="HelloIcon-L1-line HelloIcon-wipe-down HelloIcon-delay-8" />
      </div>
      <div className="HelloIcon-letter-spacer" />
      <div className="HelloIcon-L2">
        <div className="HelloIcon-L2-line HelloIcon-wipe-down HelloIcon-delay-10" />
      </div>
      <div className="HelloIcon-letter-spacer" />
      <div className="HelloIcon-O">
        <div className="HelloIcon-O-color-circle">
          <div className="HelloIcon-O-circle-blank-center HelloIcon-shrink-in HelloIcon-delay-12" />
        </div>
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default HelloIcon;
