/**
 * App icon preview with a specific size
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import shared types
import AppIcon from './types/from-server/stored/AppIcon';
import IconType from './types/from-server/stored/IconType';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // The app icon to show
  icon: AppIcon,
  // Size (width and height) of the icon in rems
  sizeInRems: number,
  // If true, fade the background color
  faded?: boolean,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const IconPreview: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    icon,
    sizeInRems,
    faded,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  let body: React.ReactNode;
  let remPadding: number = 0;

  /* --------------- SVG -------------- */

  if (icon.type === IconType.SVG) {
    let { svg } = icon;
    const { paddingPercent } = icon;
    let prevSVG = '';
    while (svg !== prevSVG) {
      prevSVG = svg;
      svg = svg.replace(/\s/g, ' ').replace(/\s\s/g, ' ');
    }

    // Calculate padding
    remPadding = ((sizeInRems * paddingPercent) / 100);

    /* eslint-disable react/no-danger */
    body = (
      <div dangerouslySetInnerHTML={{ __html: svg }} />
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div
      className="IconPreview-container d-inline-block rounded"
      style={{
        position: 'relative',
        height: `${sizeInRems}rem`,
        width: `${sizeInRems}rem`,
        padding: `${remPadding}rem`,
        overflow: 'hidden',
        boxShadow: `${sizeInRems / 50}rem ${sizeInRems / 50}rem ${sizeInRems / 20}rem 0 rgba(0, 0, 0, 0.2)`,
        backgroundColor: icon.backgroundColor,
        transition: '0.5s filter ease-out',
        filter: (
          faded
            ? 'saturate(0.3)'
            : 'saturate(1)'
        ),
      }}
    >
      {body}
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default IconPreview;
