/**
 * Modal to show status and information for contacting the requestor
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  getHumanReadableDate,
  Modal,
  ModalType,
  PopFailureMark,
  PopPendingMark,
  PopSuccessMark,
  CopiableBox,
} from 'dce-reactkit';

// Import shared types
import App from '../../shared/types/from-server/stored/App';
import InstallRequest from '../../shared/types/from-server/stored/InstallRequest';
import InstallRequestStatus from '../../shared/types/from-server/stored/InstallRequestStatus';

// Import style
import './ContactRequestorModal.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // The request of interest
  request: InstallRequest,
  // App corresponding to the request
  app: App,
  // Handler for when user wants to close the modal
  onClose: () => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const ContactRequestorModal: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    request,
    app,
    onClose,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Create email info
  let recipient: string;
  let subject: string;
  let emailBody: string;
  switch (request.status) {
    case InstallRequestStatus.Pending: {
      // Create email recipient
      recipient = request.requestorEmail;

      // Create email subject
      subject = `We Received Your Request to Install ${app.name} into ${request.courseName}`;

      // Create an email body
      // > Add intro
      const emailLines = [
        `Hi ${request.requestorFirstName},`,
        '',
        'I hope this email finds you well!',
        '',
        `On ${getHumanReadableDate(request.requestTimestamp)}, we got your request to install an app:`,
        `• App: ${app.name}`,
        `• Course: ${request.courseName}`,
        '',
        'We are currently reviewing your request and we will follow up with you once we\'ve finished installing the app for you.',
        '',
        'If you have any questions, feel free to send us an email at instructionaltechnology@dce.harvard.edu',
        '',
        'Kind regards,',
        'DCE Instructional Technology Team',
      ];
      emailBody = emailLines.join('\n');
      break;
    }
    case InstallRequestStatus.Ignored: {
      // Create email recipient
      recipient = request.requestorEmail;

      // Create email subject
      subject = `Request to Install ${app.name} into ${request.courseName}`;

      // Create an email body
      // > Add intro
      const emailLines = [
        `Hi ${request.requestorFirstName},`,
        '',
        'I hope you\'re have a great day!',
        '',
        `On ${getHumanReadableDate(request.requestTimestamp)}, we got your request to install an app:`,
        `• App: ${app.name}`,
        `• Course: ${request.courseName}`,
        '',
        'Unfortunately, we cannot grant your request at this time.',
        '',
        'If you have any questions, feel free to send us an email at instructionaltechnology@dce.harvard.edu',
        '',
        'Kind regards,',
        'DCE Instructional Technology Team',
      ];
      emailBody = emailLines.join('\n');
      break;
    }
    case InstallRequestStatus.Finished: {
      // Create email recipient
      recipient = request.requestorEmail;

      // Create email subject
      subject = `Request to Install ${app.name} into ${request.courseName}`;

      // Create an email body
      // > Add intro
      const emailLines = [
        `Hi ${request.requestorFirstName},`,
        '',
        'I hope you\'re doing well!',
        '',
        `On ${getHumanReadableDate(request.requestTimestamp)}, we got your request to install an app:`,
        `• App: ${app.name}`,
        `• Course: ${request.courseName}`,
        '',
        'We\'ve finished your request and the app is installed in the Canvas course.',
        '',
      ];
      // > Add guides
      if (app.postAddGuideURL) {
        emailLines.push('Please read this guide that outlines next steps:');
        emailLines.push(app.postAddGuideURL);
        emailLines.push('');
      }
      if (app.generalGuideURL) {
        emailLines.push('Here is a general guide on how to use the app:');
        emailLines.push(app.generalGuideURL);
        emailLines.push('');
      }
      // > Add conclusion
      emailLines.push('If you have any questions, feel free to send us an email at instructionaltechnology@dce.harvard.edu');
      emailLines.push('');
      emailLines.push('Kind regards,');
      emailLines.push('DCE Instructional Technology Team');
      emailBody = emailLines.join('\n');
      break;
    }
  }

  // Create the modal
  return (
    <Modal
      title="Contact the Requestor"
      type={ModalType.Okay}
      onClose={onClose}
    >
      {/* Ignored Mark */}
      {(
        request.status === InstallRequestStatus.Pending
      ) && (
        <div className="d-flex justify-content-center align-items-center">
          <div className="me-2">
            <PopPendingMark
              sizeRem={4}
            />
          </div>
          <div>
            <div className="fw-bold">
              Install Request Pending
            </div>
            <div>
              App:
              {' '}
              {app.name}
            </div>
            <div>
              Course:
              {' '}
              {request.courseName}
            </div>
          </div>
        </div>
      )}

      {/* Success Mark */}
      {(
        request.status === InstallRequestStatus.Finished
      ) && (
        <div className="d-flex justify-content-center align-items-center">
          <div className="me-2">
            <PopSuccessMark
              sizeRem={4}
            />
          </div>
          <div>
            <div className="fw-bold">
              Install Request Completed
            </div>
            <div>
              App:
              {' '}
              {app.name}
            </div>
            <div>
              Course:
              {' '}
              {request.courseName}
            </div>
          </div>
        </div>
      )}

      {/* Ignored Mark */}
      {(
        request.status === InstallRequestStatus.Ignored
      ) && (
        <div className="d-flex justify-content-center align-items-center">
          <div className="me-2">
            <PopFailureMark
              sizeRem={4}
            />
          </div>
          <div>
            <div className="fw-bold">
              Install Request Ignored
            </div>
            <div>
              App:
              {' '}
              {app.name}
            </div>
            <div>
              Course:
              {' '}
              {request.courseName}
            </div>
          </div>
        </div>
      )}

      {/* Divider */}
      <hr className="ContactRequestorModal-divider-line" />

      {/* Followup */}
      <div className="ContactRequestorModal-contact-form">
        {/* Title */}
        <h5>
          Email for
          {' '}
          <em>
            {request.status.substring(0, 1).toUpperCase()}
            {request.status.substring(1)}
          </em>
          {' '}
          Status:
        </h5>

        {/* Recipient */}
        <CopiableBox
          text={recipient}
          label="To:"
          minLabelWidthRem={5.5}
          textAreaId="ContactRequestorModal-recipient-text"
          copyButtonId="ContactRequestorModal-recipient-copy-button"
        />

        {/* Subject */}
        <CopiableBox
          text={subject}
          label="Subject:"
          minLabelWidthRem={5.5}
          textAreaId="ContactRequestorModal-subject-text"
          copyButtonId="ContactRequestorModal-subject-copy-button"
        />

        {/* Body */}
        <CopiableBox
          text={emailBody}
          label="Body:"
          minLabelWidthRem={5.5}
          multiline
          textAreaId="ContactRequestorModal-body-text"
          copyButtonId="ContactRequestorModal-body-copy-button"
        />

        {/* Email Client */}
        <a
          href={`mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}`}
          className="btn btn-secondary"
          aria-label="open email with subject and body"
        >
          <FontAwesomeIcon
            icon={faEnvelopeOpenText}
            className="me-2"
          />
          Open in Email Client
        </a>
      </div>
    </Modal>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default ContactRequestorModal;
