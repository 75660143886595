/**
 * This component is meant to construct the different stages of adding an app
 * by utilizing the data structure provided in AddModalBox.tsx
 * @author Jackson Parsells
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer, useEffect, useRef } from 'react';

// Add libs
import Markdown from 'markdown-to-jsx';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import relevant solid svg icons
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  LoadingSpinner,
  LogAction,
  Modal,
  ModalType,
  PopSuccessMark,
  logClientEvent,
  showFatalError,
  startMinWait,
  visitServerEndpoint,
  waitMs,
} from 'dce-reactkit';

// Import shared types
import App from '../../../shared/types/from-server/stored/App';
import LogMetadata from '../../../shared/types/from-server/LogMetadata';

// import AddModalBox
import AddModalBox from './AddModalBox';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // App to be added
  app: App,
  // Handler for when user cancels the process
  onCancelled: () => void,
  // Handler for when user is finished adding the app
  onFinished: () => void,
  // courseId of the canvas course to install the app into
  courseId: number,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

// how many milliseconds to wait before showing the button to continue
// after an app has been added
export const WAIT_FOR_CONFIRM_ADD_MS = 2000;

// how many ms to wait when transitioning between views with no buttons
export const TIME_BETWEEN_NO_BUTTON_VIEWS_MS = 2000;

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Preparing to add app (will be immediately replaced)
  Preparing = 'preparing',
  // Pre add warning view
  ConfirmBeforeAdd = 'confirm-before-adding',
  // In progress working view
  Working = 'working',
  // Add success view
  SuccessfulAdd = 'successful-add',
  // After add notification view
  ConfirmAfterAdd = 'confirm-after-adding',
  // Post add view
  Close = 'close',
}

// contains the listing of all the popup views for this modal as well as the
// order of how the orders are loaded into the modal
const fullViewOrder = [
  View.Preparing, // loading view – should be unseen
  View.ConfirmBeforeAdd, // may be omitted based on app's individual needs
  View.Working,
  View.SuccessfulAdd,
  View.ConfirmAfterAdd, // may be omitted based on app's individual needs
  View.Close,
];

/* -------- State Definition -------- */

type State = {
  // each state will now only contain the current view
  view: View,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // change the current view
  ChangeView = 'change-view',
}

// Action definitions
type Action = {
  // Change the current view
  type: ActionType.ChangeView,
  // The new view
  nextView: View,
};

/**
 * Reducer that executes actions
 * @author Jackson Parsells
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ChangeView: {
      return {
        view: action.nextView, // set to the next view passed in
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AddModal: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    app,
    onFinished,
    onCancelled,
    courseId = 103607,
  } = props;

  /* -------------- State ------------- */

  // Get the order of views for this app
  const viewOrder = fullViewOrder.filter((candidateView) => {
    if (candidateView === View.ConfirmBeforeAdd) {
      return app.messageBeforeAdd;
    }
    if (candidateView === View.ConfirmAfterAdd) {
      return app.messageAfterAdd;
    }
    // All other views
    return true;
  });

  // Initial state
  const initialState: State = {
    view: viewOrder[0],
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /* -------------- Refs -------------- */

  // Initialize refs
  const isMounted = useRef<boolean>(true);

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Add the app to Canvas
   * @author Jackson Parsells
   */
  const addAppToCanvasPage = async () => {
    try {
      await visitServerEndpoint({
        path: `/api/services/app-store/courses/${courseId}/apps/${app.id}/install`,
        method: 'POST',
      });

      // Log request
      logClientEvent({
        context: LogMetadata.Context.AppStore,
        subcontext: LogMetadata.Context.AppStore.App,
        action: LogAction.Add,
        metadata: {
          appId: app.id,
          appName: app.name,
          isRequest: false,
        },
      });

      // Update state
    } catch (err) {
      return showFatalError(err);
    }
  };

  /**
   * Go to the next step in the add process
   * @author Gabe Abrams
   * @author Jackson Parsells
   */
  const goToNextStep = async (currentView = view) => {
    // if we unmounted it, don't continue to next step
    if (!isMounted.current) {
      return;
    }

    // Figure out which view is next
    const nextView = viewOrder[viewOrder.indexOf(currentView) + 1];

    // Update the state
    dispatch({
      type: ActionType.ChangeView,
      nextView,
    });

    // check if after changing view, the app is now being added
    if (nextView === View.Working) {
      // Start min wait
      const endMinWait = startMinWait(TIME_BETWEEN_NO_BUTTON_VIEWS_MS);

      // Wait for the server to finish adding the app to the canvas page
      await addAppToCanvasPage();

      // Finish wait
      await endMinWait();

      // Go to next step after server is done adding app
      goToNextStep(nextView);
    } else if (nextView === View.SuccessfulAdd) {
      // Automatically go to next step after waiting
      await waitMs(TIME_BETWEEN_NO_BUTTON_VIEWS_MS);
      goToNextStep(nextView);
    }
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      // immediately load the "next step," which will either be the
      // confirmBeforeAdd view or the loading view based on app being added
      // initial screen will always be preparing, which doesn't have any
      // content corresponding to the app
      goToNextStep();
    },
    [],
  );

  /**
   * Unmount
   * @author Jackson Parsells
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      return () => {
        isMounted.current = false;
      };
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;
  let title: React.ReactNode;

  /* -------- Before Add View -------- */

  if (view === View.ConfirmBeforeAdd) {
    title = (
      <span className="AppModal-confirm-before-add-title-content">
        <FontAwesomeIcon icon={faExclamationTriangle} />
        Read Before Adding
      </span>
    );
    body = (
      <div className="AddModal-add-modal-body">
        <AddModalBox
          key="confirm-before-add"
          confirmContent={(
            <>
              <button
                type="button"
                className="btn btn-warning AddModal-before-add-continue-button"
                onClick={() => {
                  goToNextStep();
                }}
              >
                I understand
              </button>
              <button
                type="button"
                className="btn btn-secondary AddModal-before-add-cancel-button"
                aria-label="cancel the process of adding apps"
                onClick={onCancelled}
              >
                Cancel
              </button>
            </>
          )}
          // signal to wait before displaying continue button
          waitForConfirmMS={WAIT_FOR_CONFIRM_ADD_MS}
        >
          <Markdown>
            {app.messageBeforeAdd ?? ''}
          </Markdown>
        </AddModalBox>
      </div>
    );
  }

  /* -------- Working View -------- */

  if (view === View.Working) {
    title = 'Working...';
    body = (
      <div className="AddModal-add-modal-body">
        <AddModalBox
          key="working"
        >
          <LoadingSpinner />
        </AddModalBox>
      </div>
    );
  }

  /* -------- Post Add View -------- */

  if (view === View.SuccessfulAdd) {
    title = `${app.name} Added`;
    body = (
      <div className="AddModal-add-modal-body">
        <AddModalBox
          key="successful-add"
        >
          <div className="text-center">
            <PopSuccessMark
              sizeRem={5}
            />
          </div>
        </AddModalBox>
      </div>
    );
  }

  /* -------- After Add View -------- */

  if (view === View.ConfirmAfterAdd) {
    title = (
      <span className="AppModal-confirm-after-add-title-content">
        <FontAwesomeIcon icon={faExclamationTriangle} />
        Important Notice
      </span>
    );
    body = (
      <div className="AddModal-add-modal-body">
        <AddModalBox
          key="confirm-after-add"
          confirmContent={(
            <button
              type="button"
              className="AddModal-after-add-continue-button btn btn-warning"
              onClick={() => {
                goToNextStep();
              }}
            >
              I understand
            </button>
          )}
          // signal to wait before displaying continue button
          waitForConfirmMS={WAIT_FOR_CONFIRM_ADD_MS}
        >
          <Markdown>
            {app.messageAfterAdd ?? ''}
          </Markdown>
        </AddModalBox>
      </div>
    );
  }

  /* -------- Close View -------- */

  // TODO: show exactly where the app will be
  if (view === View.Close) {
    title = `${app.name} Added`;
    body = (
      <div className="AddModal-add-modal-body">
        <AddModalBox
          key="close"
          confirmContent={(
            <div className="text-end">
              <button
                type="button"
                className="AddModal-finish-button btn btn-secondary"
                onClick={onFinished}
              >
                Okay
              </button>
            </div>
          )}
        >
          The app has been added to your Canvas course site.
          You might need to refresh the page to see it.
        </AddModalBox>
      </div>
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <Modal
      title={title}
      type={ModalType.NoButtons}
    >
      {body}
    </Modal>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AddModal;
