/**
 * Types of add processes
 * @author Gabe Abrams
 */
enum AddType {
  AddLink = 'add-link',
  EnableLTI = 'enable-lti',
  InstallLTIByMetadata = 'install-lti-by-metadata',
  InstallLTIById = 'install-lti-by-id',
  Manual = 'manual',
  AlreadyAdded = 'already-added',
}

export default AddType;
